import Footer from '../../../components/marketing/Footer/Footer';
import NavBar from '../../../components/marketing/NavBar/NavBar';

const PrivacyPolicy = () => {
    return (
        <>
            <NavBar selected='info_hub' />

            <div className='policy-container'>
                <div className='policy-header'>
                    <p className='policy-title'>
                        Little Big Shed Privacy Policy
                    </p>
                    <p className='policy-sub-title'>
                        The Terminology below are complicated legal terms that
                        serve to protect us and make it clear the privacy terms
                        that will apply to users of Little Big Shed services.
                        The terms below are here to give you a simple
                        explanation of what the General Terms really mean in
                        plain English. These terms are not legally binding and
                        the Terminology are still the legal terms that will
                        apply if anything goes wrong.
                    </p>
                    <br />
                    <ul className='policy-list'>
                        <li>
                            Personal Information: Little Big Shed stores limited
                            Personal Information when you use our service to
                            assist in the experience of the app. Because we keep
                            this information, Little Big Shed takes on the legal
                            responsibilities that come with storing that
                            information. Whilst we endeavour to ensure the
                            protection and security of your Personal
                            Information, we are not able to guarantee the
                            complete security of information that is transmitted
                            via the internet. Accordingly, you acknowledge that
                            any information provided by you is at your own risk.
                        </li>
                        <li>
                            Your Rights: You have rights to be able to access,
                            amend and delete your Personal Information on
                            request.
                        </li>
                        <li>
                            Log data: When you visit our service, Little Big
                            Shed collects data that your browser sends to us and
                            this is then made available to Google Analytics.
                            This includes IP address, browser version, time and
                            date of visit, and other statistics.
                        </li>
                        <li>
                            Cookies: Our service uses cookies to collect data
                            and improve itself. You can either accept or refuse
                            these cookies and will know when they are being sent
                            to you. If you refuse, you might not be able to use
                            some parts of our service.
                        </li>
                        <li>
                            Security: Little Big Sheds data is encrypted and
                            securely stored via AWS database services. All
                            passwords are hashed, so no one is able to view
                            them, including Little Big Shed.
                        </li>
                        <li>
                            Social Media and other Links: When you click on a
                            third-party link to another site that is not
                            operated by us, we have no control over or
                            responsibility for the content or privacy policies
                            of these other sites.
                        </li>
                        <li>
                            Age Restriction: Those under the age of 18 are not
                            advised to access the Service.
                        </li>
                    </ul>
                    <br />
                    <strong>General Terms</strong>
                    <br />
                    <div className='policy-sub-title'>
                        Little Big Shed, is an online rental marketplace for
                        goods and equipment, such as sporting equipment or
                        tools. This platform provides borrowers and lenders
                        (Users) with the ability to connect with one another and
                        rent or lend goods and equipment (Service). This Privacy
                        Policy applies to Users that use the Little Big Shed
                        service.
                        <br />
                        <br />
                        This Privacy policy is used to inform you of Little Big
                        Shed’s policies relating to the use and disclosure of
                        Personal Information when you use our Service. When we
                        talk about Personal Information, we mean information
                        relating to you or other identifiable individuals.
                        <br />
                        <br />
                        By using the Service, you agree to be bound by the terms
                        of the then-current privacy policy (Privacy Policy).
                        Little Big Shed reserves the right, at any time, to
                        modify or replace this Privacy Policy. Please check the
                        Privacy Policy page on Little Big Shed’s website
                        periodically for changes, though we will also notify you
                        via the Platform of any changes that, in our sole
                        discretion, materially impact your use of the Service or
                        the treatment of your Personal Information. Your use of
                        the Service following the posting of any changes to the
                        Privacy Policy constitutes acceptance of those changes.
                        <br />
                        <br />
                        For your registration to Little Big Shed, Little Big
                        Shed uses a double opt-in procedure, i.e. the
                        registration is not complete until the User has
                        confirmed his or her registration by clicking on the
                        link contained in a confirmation email sent for this
                        purpose. If the User does not confirm their
                        registration, his or her registration to the Little Big
                        Shed platform will automatically be deleted from our
                        database.
                        <br />
                        <br />
                        You can unsubscribe from the Platform at any time by
                        sending an email to&nbsp;
                        <a href='mailto:support@littlebigshed.co.nz'>
                            support@littlebigshed.co.nz
                        </a>
                        <br />
                        <br />
                        The terms used in this Privacy Policy have the same
                        meanings as in our Terms of Use unless otherwise defined
                        in this Privacy Policy.
                        <br />
                        <br />
                        <strong>Collection of Personal Information</strong>
                        <br />
                        The Personal Information we collect about you, when you
                        register as a User, may include, but is not limited to:
                        <br />
                        <br />
                        <ul className='policy-list'>
                            <li>your name;</li>
                            <li>email address;</li>
                            <li>date of birth;</li>
                            <li>postal address;</li>
                            <li>phone number;</li>
                            <li>profile picture;</li>
                            <li>profile bios/information;</li>
                            <li>passwords (hashed);</li>
                            <li>IP address; and</li>
                            <li>
                                other information you may provide about yourself
                                accessing the Service
                            </li>
                        </ul>
                        <br />
                        We use Stripe to process payments and your payment
                        information is never disclosed to us and will be dealt
                        with in accordance with Stripes's privacy policy.
                        <br />
                        <br />
                        We collect Personal Information from you when you use
                        the Service available on our Platform such as; visiting
                        the Website, registering as a borrower/lender, and using
                        the App.
                        <br />
                        <br />
                        To enable us to improve our existing services and create
                        new features, we collect information about the way you
                        use the Service, including the transactions you enter
                        into on the Platform, your feedback rating, the
                        successful tasks you make, comments on each submitted
                        task and transactions you enter into with our service
                        providers.
                        <br />
                        <br />
                        We only collect the data that is necessary:
                        <br />
                        <ul className='policy-list'>
                            <li>for the operation of the Service;</li>
                            <li>
                                for the performance of a contract for which you
                                are a party
                            </li>
                            <li>
                                for which you have given consent for such
                                processing of your Personal Information; and
                            </li>
                            <li>
                                for compliance with a legal obligation to which
                                we are subject.
                            </li>
                        </ul>
                        <br />
                        We automatically collect some information from your
                        device when you use the Platform. For example, we
                        collect your session data, including your IP address,
                        the navigation software used and the reference site. We
                        may collect information about your online activity on
                        the Platform, such as reviewed content, pages visited
                        and searches. We automatically collect this type of
                        information to try to better understand your interests
                        and preferences and to better personalise your
                        experience. We also collect Personal Information in
                        order to help protect Little Big Shed and other Users
                        from misuse and fraud.
                        <br />
                        <br />
                        <strong>Using Personal Information</strong>
                        <br />
                        Your Personal Information may be collected by Little Big
                        Shed in order:
                        <br />
                        <br />
                        <ul className='policy-list'>
                            <li>
                                to enable us to operate the Service and provide
                                it to you;
                            </li>
                            <li>
                                to keep you informed about the Service,
                                features, offers and events we think you may
                                find useful or which you have requested from us;
                            </li>
                            <li>
                                for commercial purposes on behalf of our
                                partners;
                            </li>
                            <li>
                                to detect whether you are a previously banned
                                user or determine whether you are a hacker;
                            </li>
                            <li>
                                to sell or market Little Big Shed products and
                                services to you; and
                            </li>
                            <li>
                                to comply with a legal or regulatory obligation.
                            </li>
                        </ul>
                        <br />
                        You may receive marketing messages from us if you have
                        given your consent, if you have requested or if you use
                        the Service to access products or experiences from us
                        and if you have not opted out of receiving marketing
                        messages.
                        <br />
                        <br />
                        <strong>Sharing Personal Information</strong>
                        <br />
                        Subject to obligations consistent with this Privacy
                        Policy, we disclose information to our affiliates,
                        agents, contractors, and service providers in order to
                        facilitate the functioning of the Service or to perform
                        tasks that are integral to the Service, such as making
                        posts and tasks, processing transactions, fulfilling
                        requests for information, or providing support services,
                        from time to time.
                        <br />
                        <br />
                        In addition, from time to time, we may share information
                        with certain third-party companies with which Little Big
                        Shed has a business relationship. These companies may
                        send you offers based on the information you have
                        provided us and by using the Service you consent to
                        receiving such information. We have the right to share
                        your data when we investigate suspicious activity of
                        Users. In such an instance we may have to share the
                        User's IP address and/or email to administrators of
                        other platforms.
                        <br /> <br />
                        Where we are required by applicable law, we will seek
                        your consent prior to sharing your Personal Information
                        with such third parties for marketing purposes. <br />{' '}
                        <br /> We will never sell your Personal Information in
                        any circumstance to a marketing company.
                        <br /> <br />
                        <strong>Storage of Personal Information</strong>
                        <br /> <br />
                        Little Big Shed is a New Zealand company. For the
                        purposes of the General Data Protection Regulation
                        (GDPR), New Zealand is considered a country which
                        provides adequate protections for Personal Information,
                        as confirmed by the European Commission in Commission
                        Decision 2013/65/EU.
                        <br /> <br />
                        Little Big Shed uses third-party service providers (such
                        as managed hosting providers, card processors, CRM
                        systems, and technology partners) to provide the
                        necessary hardware, software, networking, storage and
                        other services that we use to operate the Service. Some
                        of these providers may not be located in New Zealand.
                        <br /> <br />
                        By using the Service or submitting any Personal
                        Information to Little Big Shed via the Service, you
                        consent to Little Big Shed and its authorised service
                        partners using your Personal Information in New Zealand
                        in order to make the Service available to you.
                        <br />
                        <br />
                        We will only retain your Personal Information for as
                        long as necessary to fulfil the purposes we collected it
                        for, including the purposes of satisfying any legal,
                        accounting or statutory reporting requirements.
                        <br />
                        <br />
                        In order to determine the appropriate retention period
                        for Personal Information, we consider the following
                        factors; the amount, nature and sensitivity of the data,
                        potential risk of harm from unauthorised use or
                        disclosure, the purposes for which we process your
                        Personal Information and whether we can achieve those
                        purposes through other means.
                        <br />
                        <br />
                        We may retain certain information subsequent to the
                        termination of your account, for example, if it is
                        necessary to fulfil our legal obligations or to
                        exercise, to maintain security, to prevent fraud and
                        abuse and to defend or enforce our rights.
                        <br />
                        <br />
                        <strong>Your Rights</strong>
                        <br />
                        You can access, amend and delete the Personal
                        Information you have provided to us by emailing our
                        Customer Services Officer at{' '}
                        <a href='mailto:support@littlebigshed.co.nz'>
                            support@littlebigshed.co.nz
                        </a>
                        <br />
                        <br />
                        If you decide to terminate your account, we will
                        deactivate it, and remove your Personal Information.
                        Remember that once your account is closed, you will no
                        longer be able to log in and access your Personal
                        Information. You can, however, open a new account at any
                        time.
                        <br /> <br />
                        By emailing our Customer Services Officer, you have the
                        following rights with regard to your Personal
                        Information:
                        <br />
                        <br />
                        <ol className='policy-list'>
                            <li>
                                Right to request access to your Personal
                                Information. This enables you to receive a copy
                                of the Personal Information we hold about you
                                and to check that we are lawfully processing it.
                            </li>

                            <li>
                                The right to request to correct your Personal
                                Information if it is inaccurate. You may also
                                supplement any incomplete Personal Information
                                we have, taking into account the purposes of the
                                processing.
                            </li>
                            <li>
                                The right to request deletion of your Personal
                                Information if;{' '}
                                <ol className='policy-list'>
                                    <li>
                                        your Personal Information is no longer
                                        necessary for the purposes for which we
                                        collected or processed; or
                                    </li>
                                    <li>
                                        you withdraw your consent if the
                                        processing of your Personal Information
                                        is based on consent and no other legal
                                        ground exists; or
                                    </li>
                                    <li>
                                        you object to the processing of your
                                        Personal Information and we do not have
                                        an overriding legitimate ground for
                                        processing; or
                                    </li>
                                    <li>
                                        your Personal Information is unlawfully
                                        processed; or
                                    </li>
                                    <li>
                                        your Personal Information has to be
                                        deleted for compliance with a legal
                                        obligation.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                The right to object to the processing of your
                                Personal Information. We will comply with your
                                request, unless we have a compelling overriding
                                legitimate interest for processing or we need to
                                continue processing your Personal Information to
                                establish, exercise or defend a legal claim.
                            </li>
                            <li>
                                The right to restrict the processing of Personal
                                Information, if:
                                <ol className='policy-list'>
                                    <li>
                                        The accuracy of your Personal
                                        Information is contested by you, for the
                                        period in which we have to verify the
                                        accuracy of the Personal Information; or
                                    </li>
                                    <li>
                                        The processing is unlawful and you
                                        oppose the deletion of your Personal
                                        Information and request restriction; or
                                    </li>
                                    <li>
                                        We no longer need your Personal
                                        Information for the purposes of
                                        processing, but your Personal
                                        Information is required by you for legal
                                        claims; or
                                    </li>
                                    <li>
                                        You have objected to the processing, for
                                        the period in which we have to verify
                                        overriding legitimate grounds
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        <br />
                        To exercise your rights you can file a request by email
                        to{' '}
                        <a href='mailto:support@littlebigshed.co.nz'>
                            support@littlebigshed.co.nz
                        </a>
                        <br />
                        <br />
                        We may need to request specific information from you to
                        help us confirm your identity and ensure your right to
                        access your Personal Information (or to exercise any of
                        your other rights). This is a security measure to ensure
                        that Personal Information is not disclosed to any person
                        who has no right to receive it. In an effort to speed up
                        our response, we may also contact you to ask you for
                        further information in relation to your request.
                        <br />
                        <br />
                        <strong>Log Data</strong>
                        <br />
                        Whenever you visit our Service, Little Big Shed collects
                        information that your browser sends to us that is called
                        Log Data. This Log Data may include information such as
                        your computer’s IP address, browser version, pages of
                        our Service that you visit, the time and date of your
                        visit, the time spent on those pages, and other
                        statistics.
                        <br />
                        <br />
                        <strong>Cookies</strong>
                        <br />
                        Cookies are files with small amounts of data that is
                        commonly used as an anonymous unique identifier. These
                        are sent to your browser from the Platform and are
                        stored on your device's hard drive.
                        <br />
                        <br />
                        Our Platform uses these “cookies” to collect information
                        and to improve our Service for users (for example to
                        temporarily remember your session in order to not ask
                        you to fill this information again each time you visit
                        the Platform or go from one page to another). Some other
                        cookies are used to generate statistics or to remember
                        your preferences.
                        <br />
                        <br />
                        You have the option to either accept or refuse these
                        cookies, and know when a cookie is being sent to your
                        computer. If you choose to refuse our cookies, you may
                        not be able to use some parts of our Service.
                        <br />
                        <br />
                        <strong>Service Providers</strong>
                        <br />
                        We may employ third-party companies and individuals due
                        to the following reasons:
                        <br />
                        <br />
                        <ol className='policy-list'>
                            <li>To facilitate our Service;</li>
                            <li>To provide the Service on our behalf;</li>
                            <li>To perform Service-related services; or</li>
                            <li>
                                To assist us in analyzing how our Service is
                                used.
                            </li>
                        </ol>
                        <br />
                        We want to inform our Service users that these third
                        parties will never have Personal Information disclosed
                        to them by Little Big Shed.
                        <br />
                        <br />
                        <strong>Security</strong>
                        <br />
                        The security of your data is important to us. We
                        endeavour to follow generally accepted standards to
                        protect the Personal Information supplied to us.
                        <br />
                        <br />
                        Little Big Shed's data is encrypted and securely stored
                        via AWS’s database services. All account passwords are
                        hashed.
                        <br />
                        <br />
                        However, no internet platform can guarantee perfect
                        security, and we implement and maintain appropriate
                        measures to protect the Personal Information you provide
                        us with.
                        <br />
                        <br />
                        <strong>Social Media and Links to Other Sites</strong>
                        <br />
                        The Platform may include links to services such as
                        Facebook and Instagram. After clicking on the link you
                        will be redirected to the page of the respective
                        provider, i.e. only after clicking on the link, User
                        information will be transferred to the respective
                        provider. For information on the handling of your data
                        when using the website of other providers, please check
                        the respective data protection information of these
                        providers.
                        <br />
                        <br />
                        Our Service may contain links to other sites. If you
                        click on a third-party link, you will be directed to
                        that site. Note that these external sites are not
                        operated by us. Therefore, we strongly advise you to
                        review the Privacy Policy of these websites. We have no
                        control over and assume no responsibility for the
                        content, privacy policies, or practices of any
                        third-party sites or services.
                        <br />
                        <br />
                        <strong>Age Restriction</strong>
                        <br />
                        The Service is not intended to be used by anyone under
                        the age of 18. If we discover that a person under 18 is
                        using the Service, we will delete any Personal
                        Information provided.
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default PrivacyPolicy;
