import Instance from '../util/axios';
import compressImage from '../util/compressImage';

const FileService = {
    uploadSingleImage: async file => {
        try {
            const formData = new FormData();
            const compressed_file = await compressImage(file);
            formData.append('file', compressed_file);
            const { data, status } = await Instance.post(
                '/file-uploads/single',
                formData,
            );
            if (status === 201) return data;
        } catch (error) {
            // console.log(error)
        }
    },

    uploadMultipleVideo: async (files, folder = '') => {
        try {
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                // const compressed_file = await compressImage(files[i]);
                formData.append('files', files[i]);
            }
            if (folder) {
                formData.append('folder', folder);
            }
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/file-uploads/multiple`, {
                method: 'post',
                body: formData,
            });
            if (response.ok) {
                const responseData = await response.json();
                return responseData;
            }
            else {
                return false;
            }
        } catch (error) {
            // console.log(error)
            return false;
        }
    },

    uploadMultipleImages: async (files, folder = '') => {
        try {
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                const compressed_file = await compressImage(files[i]);
                formData.append('files', compressed_file);
            }
            if (folder) {
                formData.append('folder', folder);
            }
            const { data, status } = await Instance.post(
                '/file-uploads/multiple',
                formData,
            );

            if (status === 201) {
                const newData = [];
                for (let i = 0; i < data.length; i++) {
                    newData.push({
                        imageKey: data[i],
                    });
                }
                return newData;
            }
        } catch (error) {
            // console.log(error)
            return false;
        }
    },

    uploadIdentityImage: async file => {
        try {
            const formData = new FormData();
            const compressed_file = await compressImage(file, true);
            formData.append('file', compressed_file);
            const { data, status } = await Instance.post(
                '/stripe/stripe-connect/identity-documents',
                formData,
            );
            if (status === 201) return data;
        } catch (error) {
            // console.log(error.response)
        }
    },

    uploadAdditionalImage: async file => {
        try {
            const formData = new FormData();
            const compressed_file = await compressImage(file, true);
            formData.append('file', compressed_file);
            const { data, status } = await Instance.post(
                '/stripe/stripe-connect/additional-documents',
                formData,
            );
            if (status === 201) return data;
        } catch (error) {
            // console.log(error.response)
        }
    },
};

export { FileService };
