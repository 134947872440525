
import {useContext, useState} from 'react';
import Button from '../Button/Button';
import ValidationTextInput from '../FormComponents/ValidationTextInput';

const AddBank = ({
    context,
    setCurrentPage,
    handleSubmit,
    user,
    addAccountLoading,
}) => {
    const {state, dispatch} = useContext(context);
    const [accountHolderNameError, setAccountHolderNameError] = useState('');
    const [bankNameError, setBankNameError] = useState('');
    const [swiftCodeError, setSwiftCodeError] = useState('');
    const [accountNumberError, setAccountNumberError] = useState('');

    const {
        accountNumber,
        accountHolderName,
        bankName,
        swiftCode
    } = state;


  console.log(swiftCode , accountHolderName ,bankName ,accountNumber)
    return (
        <div>
            <div className='BankDetailMainTitle'>Bank Deposit Details</div>
            <div className='BankDetailSubTitle'>
                Add Bank details will allow you to make transaction.
            </div>
            
            <ValidationTextInput
                value={accountHolderName}
                label='Account Holder Name'
                placeholder=''
                onChange={e => {
                    setAccountHolderNameError('');
                    let value = e.target.value;
                    dispatch({
                        type: 'SetAccountHolderName',
                        data: value ? value : e.target.value,
                    });
                }}
                errorMessage={accountHolderNameError ? accountHolderNameError : ''}
                minLength={15}
                maxLength={16}
            />

            {/* <ValidationTextInput
                value={bankName}
                label='Bank Name'
                placeholder=''
                onChange={e => {
                    setBankNameError('');
                    let value = e.target.value;
                    dispatch({
                        type: 'SetBankName',
                        data: value ? value : e.target.value,
                    });
                }}
                errorMessage={bankNameError ? bankNameError : ''}
                minLength={15}
                maxLength={16}
            /> */}

            <ValidationTextInput
                value={accountNumber}
                label='Account Number'
                placeholder='123 456 789'
                onChange={e => {
                    setAccountNumberError('');
                    let value = e.target.value?.replace(/\s|[^0-9]/g, '');
                    dispatch({
                        type: 'setAccountNumber',
                        data: value ? value : e.target.value,
                    });
                }}
                errorMessage={accountNumberError ? accountNumberError : ''}
                minLength={15}
                maxLength={16}
            />
            
            {/* <ValidationTextInput
                value={swiftCode}
                label='SWIFT/BIC'
                placeholder='ABC123'
                onChange={e => {
                    setSwiftCodeError('');
                    let value = e.target.value;
                    dispatch({
                        type: 'SetSwiftCode',
                        data: value ? value : e.target.value,
                    });
                }}
                errorMessage={swiftCodeError ? swiftCodeError : ''}
                minLength={15}
                maxLength={16}
            /> */}


            <Button
                style={{marginTop: '20px'}}
                text='Next'
                isLoading={addAccountLoading}
                isDisabled={
                    !accountNumber ||
                    !accountHolderName 
                }
                onClick={() => {
                    if (user?.address) {
                        handleSubmit();
                    } else {
                        setCurrentPage('Address');
                    }
                }}
            />
        </div>
    );
};

export default AddBank;
