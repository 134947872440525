import {Dialog, DialogContent} from '@material-ui/core';
import {createContext, useEffect, useReducer, useState} from 'react';
import {SNACKBAR_BUTTON_TYPES} from '../../../assets/Data/LBSEnum';
import Instance from '../../../util/axios';
import addBankReducer from '../../../util/reducers/addBankReducer';
import useErrorState from '../../../util/reducers/errorContext';
import useGlobalState from '../../../util/useGlobalState';
import AddAddress from '../../Bank/AddAddress';
// import AddBankStripe from '../../Bank/AddBank';
import AddBankDetails from '../../Bank/AddBankDetails';

const BankContext = createContext();

export default function AddBankModel({
    isAddBankModalOpen,
    setIsAddBankModalOpen,
    getAccountDetails,
}) {
    const [state, dispatch] = useReducer(addBankReducer, {});
    const [currentPage, setCurrentPage] = useState('Bank');
    const [addAccountLoading, setAddAccountLoading] = useState(false);
    const {errorDispatch} = useErrorState();
    const user = useGlobalState().state.user;

    useEffect(() => {
        dispatch({type: 'setAddress', data: user.address});
    }, [user]);

    const handleSubmit = async () => {
        let payload = {
            accountHolderName : state.accountHolderName,
            accountNumber: state.accountNumber,
            bankName: state.bankName,
            swiftCode : state.swiftCode
        };
        // if (state.bsb) {
        //     payload = { ...payload, bsb: state.bsb }
        // }

        try {
            setAddAccountLoading(true);
            await addBankDetails(payload);
            if (!user.address) {
                await saveAddress(state.address);
            }
            const notify = message => {
                errorDispatch({
                    type: 'openSnackBar',
                    data: {
                        message,
                        btnText: SNACKBAR_BUTTON_TYPES.CLOSE,
                        btnFunc: () => errorDispatch({type: 'closeSnackBar'}),
                    },
                });
            };
            notify('Stripe account added successfully');
        } catch (error) {
            errorDispatch({
                type: 'openSnackBar',
                data: {
                    message: 'Something went wrong.',
                    btnText: SNACKBAR_BUTTON_TYPES.CLOSE,
                    btnFunc: () => errorDispatch({type: 'closeSnackBar'}),
                },
            });
        } finally {
            setAddAccountLoading(false);
            dispatch({type: 'reset', data: {}});
            setIsAddBankModalOpen(false);
            getAccountDetails();
        }
    };

    const addStripeAccount = async payload => {
        try {
            await Instance.post('/stripe/connect-account', payload);
        } catch (error) {
            errorDispatch({
                type: 'openSnackBar',
                data: {
                    message:
                        'Invalid bank information. Please check your bank details and try again.',
                    btnText: SNACKBAR_BUTTON_TYPES.CLOSE,
                    btnFunc: () => errorDispatch({type: 'closeSnackBar'}),
                },
            });
            // }
        } finally {
            setAddAccountLoading(false);
        }
    };

    const addBankDetails = async payload => {
        try {
            await Instance.post('/bank-detail', payload);
        } catch (error) {
            errorDispatch({
                type: 'openSnackBar',
                data: {
                    message:
                        'Invalid bank information. Please check your bank details and try again.',
                    btnText: SNACKBAR_BUTTON_TYPES.CLOSE,
                    btnFunc: () => errorDispatch({type: 'closeSnackBar'}),
                },
            });
            // }
        } finally {
            setAddAccountLoading(false);
        }
    };

    const saveAddress = async payload => {
        try {
            await Instance.post('/user-addresses', payload);
        } catch (error) {
            // console.log(error)
        }
    };
    return (
        <Dialog
            open={isAddBankModalOpen}
            onClose={() => setIsAddBankModalOpen(false)}
        >
            <DialogContent className='DeleteModalContainer'>
                <BankContext.Provider value={{state, dispatch}}>
                    {currentPage === 'Bank' ? (
                        <AddBankDetails
                            context={BankContext}
                            setCurrentPage={setCurrentPage}
                            handleSubmit={handleSubmit}
                            addAccountLoading={addAccountLoading}
                            setAddAccountLoading={setAddAccountLoading}
                            user={user}
                        />
                    ) : (
                        !user?.address && (
                            <AddAddress
                                context={BankContext}
                                setCurrentPage={setCurrentPage}
                                setIsAddBankModalOpen={setIsAddBankModalOpen}
                                getAccountDetails={getAccountDetails}
                                handleSubmit={handleSubmit}
                                addAccountLoading={addAccountLoading}
                                setAddAccountLoading={setAddAccountLoading}
                            />
                        )
                    )}
                </BankContext.Provider>
            </DialogContent>
        </Dialog>
    );
}
