import React, { useState } from 'react';
import './SearchInput.css';
import CategorySelect from '../../categorySelect/categorySelect';
import RatingSelect from '../../ratingSelect/ratingSelect';
import SelectInput from '../../selectInput/selectInput';
import TextInput from '../../textInput/textInput';
import SearchIcon from '../../../assets/Icons/SearchIcon.svg';
import { useHistory } from 'react-router-dom';
import MapsAutocomplete from '../../mapsAutocomplete/MapsAutocomplete';

const SearchInput = () => {
    const [keywords, setKeywords] = useState('');
    const [category, setCategory] = useState('');
    const [priceMin, setPriceMin] = useState('');
    const [priceMax, setPriceMax] = useState('');
    const [rating, setRating] = useState('');
    const [delivery, setDelivery] = useState('');
    const [address, setAddress] = useState();
    const history = useHistory('');

    const formatSearchParams = () => {
        let string = '';
        if (keywords) {
            string = string.concat('?keyword=' + keywords);
        } else {
            string = string.concat('?keyword=');
        }
        if (category) {
            string = string.concat('&category=' + encodeURIComponent(category));
        }
        if (priceMax) string = string.concat('&maxPrice=' + priceMax);
        if (priceMin) string = string.concat('&minPrice=' + priceMin);
        if (rating) string = string.concat('&rating=' + rating);
        if (address && address.lng && address.lat) {
            string = string.concat(
                `&lat=${address.lat}&lng=${address.lng}&distance=${10}`,
            );
        }
        if (delivery === '') {
        } else if (delivery === 'Available') {
            string = string.concat('&delivery=1');
        } else {
            string = string.concat('&delivery=0');
        }
        history.push(`/search/${string}`);
    };

    const handleSubmit = () => {
        formatSearchParams();
    };

    return (
        <>
            <div className='SearchSectionFilters'>
                <div className='SearchFiltersRowFlex responsive_container'>
                    <div className='SearchInputContainer'>
                        <TextInput
                            width='100%'
                            label='Keywords'
                            fontSize='16px'
                            onChange={e => setKeywords(e.target.value)}
                        />
                    </div>
                    <div className='SearchInputContainer'>
                        <CategorySelect
                            width='100%'
                            label='Category'
                            setCategory={setCategory}
                            value={category}
                        />
                    </div>
                    <div className='input-25'>
                        <MapsAutocomplete
                            setAddress={address => setAddress(address)}
                            small
                            isInSearchBar={true}
                        />
                    </div>
                </div>

                <hr className='DividingLine' />

                <div className='SearchFiltersRowFlex pt-4 responsive_container'>
                    <div className='PriceFilterSearch new-inputs'>
                        <div className='PriceFilterContainer'>
                            <TextInput
                                className='PriceFilterSelect'
                                value={priceMin}
                                borders={false}
                                label='$ Min'
                                onChange={e => setPriceMin(e.target.value)}
                            />
                        </div>
                        <div className='PriceFilterContainer'>
                            <TextInput
                                className='PriceFilterSelect'
                                label='$ Max'
                                value={priceMax}
                                onChange={e => setPriceMax(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='input-25'>
                        <RatingSelect
                            width='100%'
                            label='Minimum Rating'
                            fontSize='17px'
                            onChange={e => setRating(e.target.value)}
                        />
                    </div>
                    <div className='input-25'>
                        {/* <SelectInput
                            value={delivery}
                            width='100%'
                            label='Delivery'
                            borders={true}
                            fontSize='17px'
                            paddingTop='20px'
                            options={['Available', 'Unavailable']}
                            onChange={e => setDelivery(e.target.value)}
                        /> */}
                    </div>
                </div>
            </div>
            <div>
                <button
                    className='SearchButtonLarge responsive_search_button'
                    onClick={handleSubmit}
                >
                    <div className='SearchButtonLargeFlex'>
                        <img
                            src={SearchIcon}
                            alt='search button'
                            className='SearchIcon35'
                        />
                        <div>Search</div>
                    </div>
                </button>
            </div>
        </>
    );
};

export default SearchInput;
