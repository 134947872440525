import './PhoneNumberInput.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ValidationPopup from '../ValidationPopup/ValidationPopup';

const PhoneNumberInput = ({
    label,
    placeholder,
    value,
    onChange,
    errorMessage,
    inLineError,
    onBlur,
}) => {
    const handleInputChange = number => {
        onChange(number);
        if (number?.length >= 11 && onBlur) {
            onBlur(number);
        }
    };
    const handleBlur = number => {
        if (number?.length < 12 && onBlur) {
            onBlur(number);
        }
    };
    return (
        <div className={'ValidationInputContainer'}>
            <div className='LoginHeader--NoMargin-1'>{label}</div>
            <div className='phonenumber-note'>Enter your phone number in 02xxx format without the country code.</div>
            <div className='LoginInputValidationContainer'>
                <PhoneInput
                    country={'nz'}
                    onlyCountries={['nz']}
                    placeholder={placeholder ?? ''}
                    value={value}
                    inputProps={{
                        maxLength: 13,
                    }}
                    countryCodeEditable={false}
                    onBlur={e => handleBlur(e?.target?.value)}
                    enableSearch={true}
                    autoFormat={false}
                    onChange={handleInputChange}
                    containerClass={'phone_container'}
                    inputClass={'phone_input_container'}
                    buttonClass={'phone_btn_container'}
                    dropdownClass={'phone_dropdown_container'}
                />

                {errorMessage && !inLineError && (
                    <ValidationPopup
                        errorText={errorMessage}
                        hide={false}
                        errorHeader={`Invalid ${label}`}
                    />
                )}
            </div>
            {errorMessage && inLineError && (
                <div className='InLineErrorContainer red_bg'>
                    <div className='ValidationPopup__Header'>{`Invalid ${label}`}</div>
                    {errorMessage}
                </div>
            )}
        </div>
    );
};

export default PhoneNumberInput;
