const cardElementOptions = {
    style: {
        base: {
            color: 'black',
            fontFamily: '"DMSans", sans-serif',
            fontWeight: 'bold',
            fontSmoothing: 'antialiased',
            fontSize: '18px',
            '::placeholder': {
                color: 'rgb(133,133,133)',
            },
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
        },
    },
};

export default cardElementOptions;
