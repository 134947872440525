import React, {useState} from 'react';
import './categorySelect.css';
import {makeStyles, withStyles} from '@material-ui/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import ArrowDown from '@material-ui/icons/ExpandMore';
import {ITEM_CATEGORIES_QUERY} from '../../assets/Data/LBSSelectOptions';

const BootstrapInput = withStyles(theme => ({
    input: {
        color: '#000000',
        textAlign: 'left',
        fontFamily: ['DMSans, sans-serif'].join(','),

        '&:focus': {
            // backgroundColor: '#FFFFFF',
            borderRadius: '15px',
        },
    },
}))(InputBase);

const useStyles = makeStyles({
    inputDiv: props => ({
        width: props.width,
        position: 'relative',
        margin: props.margin,
        Height: '48px',
    }),
    inputLabel: props => ({
        display: props.label === '' ? 'none' : 'block',
        fontSize: '14px',
        fontWeight: '500',
        color: '#fff',
        textAlign: 'left',
    }),
    dropDown: props => ({
        border: props.thinBorder ? '1px solid #95272f' : '1px solid #95272f',
        borderRadius: '10px',
        boxSizing: 'content-box',
        '& .MuiMenuItem-root': {
            fontFamily: 'DMSans, sans-serif',
            fontSize: '14px',
        },
        '& .selectLabel': {
            padding: '0 8px',
            color: '#404040',
            marginLeft: '10px',
        },
    }),
    select: props => ({
        border: props.thinBorder ? '1px solid #95272f' : '1px solid #95272f',
        fontSize: props.thinBorder ? '16px' : '16px',
        fontWeight: props.thinBorder ? 'bold' : 'normal',
        '& .MuiSvgIcon-root': {
            color: '#95272f',
            marginRight: '6px',
        },
    }),
});

export default function CategorySelect(props) {
    const classes = useStyles(props);
    const [category, setCategory] = useState('');

    const handleChange = ({target}) => {
        const category = target.value;
        setCategory(category.value);
        props.setCategory(category.value);
    };

    const defaultValue = props.value ?? '';

    return (
        <div className={`${classes.inputDiv}`}>
            <label className={`${classes.inputLabel}`}>{props.label}</label>
            <Select
                onChange={handleChange}
                input={<BootstrapInput />}
                className={`SelectInput ${
                    props.thinBorder ? 'SelectInput--Thin' : ''
                } ${classes.select}`}
                IconComponent={ArrowDown}
                value={defaultValue ?? category}
                MenuProps={{
                    anchorOrigin: {
                        vertical: -22,
                        horizontal: -2,
                    },
                    transformOrigin: {
                        vertical: 0,
                        horizontal: 0,
                    },
                    getContentAnchorEl: null,
                    classes: {
                        paper: classes.dropDown,
                    },
                }}
            >
                <label className={`selectLabel ${classes.inputLabel}`}>
                    {props.label}
                </label>
                <div className='DropDownTitle'>{category}</div>
                <hr className='hl' />

                {props.value && (
                    <MenuItem value={props.value ? defaultValue : ''}>
                        <div className='CatIconContainer'></div>
                        {props.value ? defaultValue : ''}
                    </MenuItem>
                )}

                {ITEM_CATEGORIES_QUERY.map((category, index) => (
                    <MenuItem value={category} key={index}>
                        <div className='CatIconContainer'>{category.icon}</div>
                        {category.value}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
}
