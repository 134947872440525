import React from 'react';
import './textInput.css';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles({
    textInput: props => ({
        fontSize: props.fontSize,
        padding: props.padding,
    }),
    inputDiv: props => ({
        width: props.width,
        position: 'relative',
        margin: props.margin,
    }),
    inputLabel: props => ({
        display: props.label === '' ? 'none' : 'block',
        fontSize: '14px',
        fontWeight: '500',
        textAlign: 'left',
        color: '#fff',
    }),
});

export default function TextInput(props) {
    const classes = useStyles(props);
    return (
        <div className={`${classes.inputDiv}`}>
            <label className={`${classes.inputLabel}`}>{props.label}</label>
            <input
                type={`${props.type ? props.type : 'text'}`}
                value={props.value}
                placeholder={props.label}
                className={`TextInput ${classes.textInput}`}
                onChange={props.onChange}
                style={props.style}
            ></input>
        </div>
    );
}
