import {Dialog, DialogContent} from '@material-ui/core';

export default function AddCardModel({
    addCardOpen,
    setAddCardOpen,
    addcardFunction,
}) {
    return (
        <Dialog
            open={addCardOpen}
            onClose={() => setAddCardOpen(false)}
            className='cus-modal'
        >
            <DialogContent className='BorrowerMain'>
                <span className='ApplicationModalTitle'>Alert</span>
                <br></br>
                <span className='ApplicationModalText'>
                    You don't have a card to borrow this item
                </span>
                <div
                    className='ApplicationModalButton'
                    onClick={addcardFunction}
                    style={{margin: '1rem 0'}}
                >
                    <span className='ApplicationModalButtonText'>Add Card</span>
                </div>
            </DialogContent>
        </Dialog>
    );
}
