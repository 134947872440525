const StatusDisputed = () => {
    return (
        <div className='TradeStatusContentContainer'>
            <p>
                This booking has been disputed. Support will contact you
                shortly.
            </p>
        </div>
    );
};

export default StatusDisputed;
