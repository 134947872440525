import './ratingFiller.css';
import {ReactComponent as StarOutline} from './../../assets/Icons/StarOutline.svg';
import {ReactComponent as StarFilled} from './../../assets/Icons/StarFilled.svg';
import {ReactComponent as StarFilled25} from './../../assets/Icons/StarFilled25.svg';
import {ReactComponent as StarFilled50} from './../../assets/Icons/StarFilled50.svg';
import {ReactComponent as StarFilled75} from './../../assets/Icons/StarFilled75.svg';

export default function ratingFiller(props) {
    let ratings = [1, 2, 3, 4, 5];
    var partialNumber = Number((props.rating % 1).toFixed(2));
    return (
        <div className='RatingStars'>
            {ratings.map((dt, index) => {
                if (props.rating >= dt) {
                    return (
                        <StarFilled
                            fill='#E9D8B4'
                            className='StarIcon'
                            key={index}
                        />
                    );
                } else {
                    if (partialNumber <= 0.25 && partialNumber > 0) {
                        partialNumber = 0;
                        return (
                            <StarFilled25 className='StarIcon' key={index} />
                        );
                    } else if (partialNumber <= 0.5 && partialNumber > 0.25) {
                        partialNumber = 0;
                        return (
                            <StarFilled50 className='StarIcon' key={index} />
                        );
                    } else if (partialNumber <= 0.99 && partialNumber > 0.5) {
                        partialNumber = 0;
                        return (
                            <StarFilled75 className='StarIcon' key={index} />
                        );
                    } else {
                        return <StarOutline className='StarIcon' key={index} />;
                    }
                }
            })}
            {/* {props.rating >= 1 ? (
        <StarFilled fill='#E9D8B4' className='StarIcon' />
      ) : (
        <StarOutline className='StarIcon' />
      )}
      {props.rating >= 2 ? (
        <StarFilled fill='#E9D8B4' className='StarIcon' />
      ) : (
        <StarOutline className='StarIcon' />
      )}
      {props.rating >= 3 ? (
        <StarFilled fill='#E9D8B4' className='StarIcon' />
      ) : (
        <StarOutline className='StarIcon' />
      )}
      {props.rating >= 4 ? (
        <StarFilled fill='#E9D8B4' className='StarIcon' />
      ) : (
        <StarOutline className='StarIcon' />
      )}
      {props.rating >= 5 ? (
        <StarFilled fill='#E9D8B4' className='StarIcon' />
      ) : (
        <StarOutline className='StarIcon' />
      )} */}
        </div>
    );
}
