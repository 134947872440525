import {useContext, useEffect, useState} from 'react';
import {validate} from 'validate.js';
import Arrow from '../../assets/Icons/Arrow';
import {userAddressConstraints} from '../../util/validationConstraints';
import Button from '../Button/Button';
import ValidationTextInput from '../FormComponents/ValidationTextInput';
import MapsAutocomplete from '../mapsAutocomplete/MapsAutocomplete';

const ADDRESS_INITIAL_VALUES = {
    streetNumber: '',
    streetName: '',
    suburb: '',
    state: '',
    postCode: '',
    country: '',
    fullAddress: '',
    city: '',
};

export default function AddAddress({
    context,
    setCurrentPage,
    setIsAddBankModalOpen,
    getAccountDetails,
    addAccountLoading,
    setAddAccountLoading,
    handleSubmit,
}) {
    const {dispatch} = useContext(context);
    const [address, setAddress] = useState(ADDRESS_INITIAL_VALUES);
    const [errorMessages, setErrorMessages] = useState({});
    const [isSelectAddress, setIsSelectAddress] = useState(false);
    useEffect(() => {
        if (Object.keys(errorMessages).length > 0) {
            const valid = validateInputs();
            if (valid) {
                setErrorMessages({});
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address]);

    const getErrorMessage = inputName => {
        if (Object.keys(errorMessages).length === 0) return null;
        for (const key in errorMessages) {
            if (Object.keys(errorMessages)[0] === inputName)
                return errorMessages[key][0];
        }
    };

    const validateInputs = () => {
        const {
            streetNumber,
            streetName,
            suburb,
            state,
            postCode,
            country,
            city,
        } = address;
        const validationErrors = validate(
            {
                streetNumber,
                streetName,
                suburb,
                state,
                postCode,
                country,
                city,
            },
            userAddressConstraints,
        );
        if (validationErrors) {
            setErrorMessages(validationErrors);
            return false;
        }

        setErrorMessages({});
        return true;
    };

    const checkAddress = () => {
        const valid = validateInputs();
        if (!valid) return;
        dispatch({type: 'setAddress', data: address});
        dispatch({type: 'setShedAddress', data: address});
        handleSubmit();
    };

    const handleAddressSelect = address => {
        const addressObj = {
            country: address.country ?? '',
            state: address.state ?? '',
            fullAddress: address.fullAddress ?? '',
            postCode: address.postCode ?? '',
            streetName: address.streetName ?? '',
            streetNumber: address.streetNumber ?? '',
            suburb: address.suburb ?? '',
            city: address.city ? address.city : (address.suburb ?? ''),
            lat: address.lat ?? 0,
            lng: address.lng ?? 0,
        };
        setAddress(addressObj);
        dispatch({type: 'setAddress', data: addressObj});
    };

    return (
        <div className='RegistrationWrapper'>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <div className='back-arrow'>
                    <Arrow onClick={() => setCurrentPage('Bank')} />
                </div>
            </div>
            <div className='BankDetailMainTitle'>Shed Location</div>
            <div className='BankDetailSubTitle' style={{marginBottom: '20px'}}>
                If you would like to share your shed with users, Little big shed
                will need to know your location in order for borrowers to find
                you.
            </div>

            <MapsAutocomplete
                setIsSelectAddress={value => setIsSelectAddress(value)}
                setAddress={address => {
                    handleAddressSelect(address);
                }}
            />
            <div className='flex flex-col md:mb-5 w-full -translate-y-5'>
                <ValidationTextInput
                    width='100%'
                    value={address.streetNumber}
                    label='Street Number'
                    fontSize='20px'
                    isDisabled={!isSelectAddress}
                    onChange={e =>
                        setAddress({...address, streetNumber: e.target.value})
                    }
                    errorMessage={getErrorMessage('streetNumber')}
                />
                <ValidationTextInput
                    width='100%'
                    value={address.streetName}
                    label='Street Name'
                    isDisabled={!isSelectAddress}
                    fontSize='20px'
                    onChange={e =>
                        setAddress({...address, streetName: e.target.value})
                    }
                    errorMessage={getErrorMessage('streetName')}
                />
                <ValidationTextInput
                    width='100%'
                    label='City'
                    isDisabled={!isSelectAddress}
                    value={address.city}
                    fontSize='20px'
                    onChange={e =>
                        setAddress({...address, city: e.target.value})
                    }
                    errorMessage={getErrorMessage('city')}
                />
                <ValidationTextInput
                    width='100%'
                    value={address.state}
                    label='State'
                    isDisabled={!isSelectAddress}
                    fontSize='20px'
                    onChange={e =>
                        setAddress({...address, state: e.target.value})
                    }
                    errorMessage={getErrorMessage('state')}
                />
                <ValidationTextInput
                    width='100%'
                    label='Post Code'
                    value={address.postCode}
                    isDisabled={!isSelectAddress}
                    fontSize='20px'
                    onChange={e =>
                        setAddress({...address, postCode: e.target.value})
                    }
                    errorMessage={getErrorMessage('postCode')}
                />
                <ValidationTextInput
                    width='100%'
                    label='Country'
                    value={address.country}
                    isDisabled={!isSelectAddress}
                    fontSize='20px'
                    onChange={e =>
                        setAddress({...address, country: e.target.value})
                    }
                    errorMessage={getErrorMessage('country')}
                />
            </div>
            <Button
                text='Save Bank Account details'
                isDisabled={!address?.fullAddress}
                isLoading={addAccountLoading}
                onClick={() => {
                    checkAddress();
                }}
            />
        </div>
    );
}
