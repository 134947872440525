import moment from 'moment';

const LendingTimeline = ({events}) => {
    const formatEventDate = date => {
        return moment(date).format('hA dddd Do MMM');
    };

    const formatEventName = event => {
        return event.toLowerCase().replaceAll('_', ' ');
    };

    function compareDates(a, b) {
        return (
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
    }

    const renderEvents = () => {
        const sortedEvents = events.sort(compareDates);

        return sortedEvents.map((event, index) => {
            return (
                <div key={index} className='lending-box'>
                    <p className='lending-events-status'>
                        {formatEventName(event.event)}
                    </p>
                    <p>{formatEventDate(event.createdAt)}</p>
                </div>
            );
        });
    };

    return <div className='lending-events'>{renderEvents()}</div>;
};

export default LendingTimeline;
