import './ValidationPopup.css';

export default function ValidationPopup({errorHeader, errorText, label}) {
    let duplicateError = [
        'Email is already in use',
        'Phone Number is already in use',
    ].includes(errorText);

    return (
        <div className='InLineErrorContainer'>
            {!duplicateError && (
                <div className='ValidationPopup__Header'>
                    {label ? `Invalid ${label}` : errorHeader}
                </div>
            )}
            {errorText}
        </div>
    );
}
