import React from 'react';
import {Dialog} from '@material-ui/core';
import {DialogContent} from '@material-ui/core';

export default function UpgradeToLenderConfirmationModal({
    isModalOpen,
    handleConfirm,
    setIsModalOpen,
}) {
    return (
        <Dialog
            className='test'
            open={isModalOpen}
            onClose={() => setIsModalOpen(!isModalOpen)}
        >
            <DialogContent className='UpgradeToLenderModalContainer'>
                <div className='DeleteModalTextContainer'>
                    <div className='DeleteModalHeading'>
                        Become Little Big Shed Lender ?
                    </div>
                    <div className='DeleteModalText'>
                        To become a Little Big Shed lender, we need a couple
                        more details from you to start making successful trades
                        on the platform.
                    </div>
                    {/* <div className="DeleteModalText">Are you sure you want to do this?</div> */}
                </div>
                <div
                    className='ItemButtons'
                    style={{
                        justifyContent: 'center',
                        justifySelf: 'flex-end',
                    }}
                >
                    <button
                        className='ButtonAvailability'
                        onClick={() => setIsModalOpen(!isModalOpen)}
                    >
                        <div className='ItemButtonFlex'>Go back</div>
                    </button>
                    <button
                        className='SearchButtonLarge'
                        onClick={() => {
                            setIsModalOpen(!isModalOpen);
                            handleConfirm();
                        }}
                    >
                        Let's, Go!
                    </button>
                </div>
            </DialogContent>
        </Dialog>
    );
}
