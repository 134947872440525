export default function ShowPasswordIcon({onClick}) {
    return (
        <div onClick={onClick} style={{cursor: 'pointer'}}>
            <svg
                width='20'
                height='18'
                viewBox='0 0 724 534'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M362 0.333344C266.918 0.333344 187.239 46.8207 128.604 97.1983C69.756 147.759 28.919 204.92 10.7957 232.66C-2.898 253.617 -2.898 280.383 10.7957 301.34C28.919 329.08 69.756 386.24 128.604 436.803C187.239 487.18 266.918 533.667 362 533.667C457.083 533.667 536.763 487.18 595.4 436.803C654.247 386.24 695.083 329.08 713.207 301.34C726.9 280.383 726.9 253.617 713.207 232.66C695.083 204.92 654.247 147.759 595.4 97.1983C536.763 46.8207 457.083 0.333344 362 0.333344ZM278.667 267C278.667 220.977 315.977 183.667 362 183.667C408.023 183.667 445.333 220.977 445.333 267C445.333 313.023 408.023 350.333 362 350.333C315.977 350.333 278.667 313.023 278.667 267ZM362 117C279.157 117 212 184.157 212 267C212 349.843 279.157 417 362 417C444.843 417 512 349.843 512 267C512 184.157 444.843 117 362 117Z'
                    fill='black'
                />
            </svg>
        </div>
    );
}
