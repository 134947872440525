import React from 'react';

export default function BrownTriangle() {
    return (
        <div className='SentMessageTriangle'>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='18'
                height='41'
                viewBox='0 0 18 41'
            >
                <defs>
                    <clipPath id='clip-Brown_-_Message_bubble_triangle'>
                        <rect width='18' height='41' />
                    </clipPath>
                </defs>
                <g
                    id='Brown_-_Message_bubble_triangle'
                    data-name='Brown - Message bubble triangle'
                    clipPath='url(#clip-Brown_-_Message_bubble_triangle)'
                >
                    <path
                        id='Subtraction_35'
                        data-name='Subtraction 35'
                        d='M49.648,44.063H37.859V2.863a7.292,7.292,0,0,1,.4.613l17.413,30.16a6.952,6.952,0,0,1-6.021,10.427Z'
                        transform='translate(-38.301 -2.816)'
                        fill='#e9d8b4'
                    />
                </g>
            </svg>
        </div>
    );
}
