import axios from 'axios';
import Instance from '../util/axios';

const networkErrorMessage =
    'There was an error with your connection, please try again';

namespace DisputeService {
    export const disputeBooking = async (bookingId: string, payload: any) => {
        try {
            const {data} = await Instance.post(
                `bookings/${bookingId}/dispute`,
                payload,
            );
            return data;
        } catch (error: unknown) {
            if (error && axios.isAxiosError(error)) {
                // console.log(error.response?.data)
            }
            throw Error('Error disputing booking');
        }
    };

    export const getUserDisputes = async () => {
        try {
            const {data} = await Instance.get(`booking-disputes/disputes`);
            return data;
        } catch (error: any) {
            if (error && axios.isAxiosError(error)) {
                if (
                    error?.code === 'ERR_NETWORK' ||
                    error?.code === 'ECONNABORTED'
                )
                    throw Error(networkErrorMessage);
            }
            throw Error(error?.response?.data?.message);
        }
    };
}

export default DisputeService;
