import {useContext, useState} from 'react';
import {ReactComponent as Logo} from '../../../assets/Logos/LogoRed.svg';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {makeStyles} from '@material-ui/styles';
import Button from '../../../components/Button/Button';
import {FileService} from '../../../services/FileService';
import {v4 as uuidv4} from 'uuid';
import {CircularProgress} from '@material-ui/core';
import {POST_ITEM_PAGE} from '../../../assets/Data/LBSEnum';

const useStyles = makeStyles({
    button: {
        width: 50,
        height: 50,
        backgroundColor: '#B43B4C',
        '&:hover': {
            backgroundColor: '#cf3247',
        },
    },
    icon: {
        fontSize: 40,
        color: '#FFF',
        height: '20px',
        width: '20px',
    },
    buttonDelete: {
        position: 'absolute',
        top: -10,
        right: -10,
        width: 20,
        height: 20,
        backgroundColor: '#B43B4C',
        '&:hover': {
            backgroundColor: '#cf3247',
        },
    },
});

export default function UploadAttachmentPictures({
    disputAttachments,
    setDisputAttachments,
    setDisputAttachmentsLinks,
    disputAttachmentsLinks,
}) {
    const [isUploading, setIsUploading] = useState(false);
    const classes = useStyles();

    const handleChange = async ({target}) => {
        try {
            setIsUploading(true);
            const files = target.files;
            if (!files.length) return;
            const fileLinks = await FileService.uploadMultipleImages(files);
            if (!fileLinks) return;
            let newPictures = disputAttachments.map(picture => picture);
            for (let i = 0; i < files.length; i++) {
                newPictures.push({
                    preview: URL.createObjectURL(files[i]),
                    raw: files[i],
                    id: uuidv4(),
                });
            }
            setDisputAttachments(newPictures);
            setDisputAttachmentsLinks([
                ...disputAttachmentsLinks,
                ...fileLinks,
            ]);
        } catch (error) {
            // console.log(error.response)
        } finally {
            setIsUploading(false);
        }
    };

    const handleDelete = (id, idx) => {
        const newPictures = disputAttachments.filter(
            picture => picture.id !== id,
        );
        const newPicturesLinks = disputAttachmentsLinks.filter(
            (dt, index) => index !== idx,
        );
        setDisputAttachments(newPictures);
        setDisputAttachmentsLinks(newPicturesLinks);
    };
    return (
        <div className='main-container-images'>
            <div className='Dispute__ItemPictures__Container'>
                {disputAttachments?.map((picture, index) => {
                    return (
                        <div
                            className='Dispute__ItemPictures__Preview'
                            key={index}
                        >
                            <IconButton
                                aria-label='delete'
                                className={classes.buttonDelete}
                                onClick={() => handleDelete(picture.id, index)}
                            >
                                <RemoveIcon className={classes.icon} />
                            </IconButton>
                            <img
                                src={picture.preview}
                                alt='posting item'
                                className='ProfilePicturePreview'
                                loading='lazy'
                            />
                        </div>
                    );
                })}
                {isUploading && (
                    <div
                        className='Dispute__ItemPictures__Preview'
                        style={{display: 'flex', justifyContent: 'center'}}
                    >
                        <CircularProgress color={'secondary'} />
                    </div>
                )}
            </div>
            <input
                type='file'
                id='selectFile'
                accept='image/png, image/gif, image/jpeg , image/jpg , image/svg , image/webp'
                multiple
                style={{display: 'none'}}
                onChange={handleChange}
            />

            {disputAttachments?.length <= 3 && (
                <div className='Dispute__ItemPictures__Add__Container'>
                    <IconButton
                        aria-label='delete'
                        className={classes.button}
                        onClick={() =>
                            document.getElementById('selectFile').click()
                        }
                    >
                        <AddIcon className={classes.icon} />
                    </IconButton>
                </div>
            )}
        </div>
    );
}
