import Instance from '../util/axios';
class NotificationService {
    removeFCMToken = async (fcmToken: string) => {
        try {
            const isTokenRemove = await Instance.delete(
                `/notification-tokens/${fcmToken}`,
            );
        } catch (error: any) {
            // console.log(
            //     'Error while removing the token',
            //     JSON.stringify(error.response.data, null, 3)
            // )
        }
    };

    saveFCMToken = async (fcmToken: string) => {
        try {
            const isTokenSaved = await Instance.post(`/notification-tokens`, {
                token: fcmToken,
            });
        } catch (error: any) {
            return false;
        }
    };
}

export default NotificationService;
