import {ToastContainer, toast} from 'react-toastify';
import {FaBell} from 'react-icons/fa';

export const notificationToast = (title, body) =>
    toast(
        <div
            style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '0px 5px',
            }}
        >
            <FaBell height='25px' width='25px' className='notification__Icon' />
            {'  '}
            <div className='notification-container'>
                <span className='notification-title'>{title}</span>
                <span className='notification-body'>{body}</span>
            </div>
        </div>,
    );
