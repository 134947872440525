import {useState} from 'react';
import ValidationPopup from '../ValidationPopup/ValidationPopup';
import ShowPasswordIcon from '../../assets/Icons/ShowPasswordIcon';
import './ValidationTextInput.css';
import ShowPasswordDefaultIcon from '../../assets/Icons/ShowPasswordDefaultIcon';
import validate from 'validate.js';
import {emailConstraints} from '../../util/validationConstraints';
export default function ValidationEmailInput({
    onChange,
    errorMessage,
    label,
    placeholder,
    passwordInput,
    errorHeader,
    value,
    inLineError,
    inputType,
    onBlur,
}) {
    const [isInputHidden, setIsInputHidden] = useState(true);
    const [showPasswordIcon, setShowPasswordIcon] = useState(true);

    const togglePasswordVisibility = () => {
        setIsInputHidden(!isInputHidden);
        setShowPasswordIcon(!showPasswordIcon);
    };

    const handleInputChange = event => {
        onChange(event);
        if (event.target.value) {
            const validationErrors = validate(
                {email: event.target.value},
                emailConstraints,
            );
            if (typeof validationErrors === 'undefined' && onBlur) {
                onBlur(event.target.value);
            }
        }
    };

    return (
        <div className={'ValidationInputContainer'}>
            <div className='LoginHeader'>{label}</div>
            <div className={inLineError && 'ValidationInputErrorContainer'}>
                <div className='LoginInputValidationContainer'>
                    <input
                        placeholder={placeholder}
                        className='ValidationInput'
                        onChange={handleInputChange}
                        value={value}
                        type={inputType}
                        step='1'
                        onWheel={e => e.target.blur()}
                    />
                </div>
                {errorMessage && !inLineError ? (
                    <ValidationPopup
                        errorText={errorMessage}
                        errorHeader={errorHeader}
                        label={label}
                    />
                ) : null}
            </div>
        </div>
    );
}
