import React, {useEffect, useState} from 'react';
import Instance from '../../../../util/axios';
import ValidationTextInput from '../../../../components/FormComponents/ValidationTextInput';
import Button from '../../../../components/Button/Button';
import useGlobalState from '../../../../util/useGlobalState';
import EditIcon from '../../../../assets/Icons/EditIcon';
import './StripeAccountDetails.css';
import Arrow from '../../../../assets/Icons/Arrow';
import {CircularProgress} from '@material-ui/core';
import {SNACKBAR_BUTTON_TYPES} from '../../../../assets/Data/LBSEnum';
import useErrorState from '../../../../util/reducers/errorContext';
// import AddBank from '../../../../components/Bank/AddBank';
import AddBankModel from '../../../../components/modals/AddBankModel/AddBankModel';

export default function AccountDetails() {
    const {state, dispatch} = useGlobalState();
    const {user} = state;
    const {errorDispatch} = useErrorState();
    const [isEditingAccount, setIsEditingAccount] = useState(false);
    const [isUpdateAccountLoading, setIsUpdateAccountLoading] = useState(false);
    const [updateAccountError, setUpdateAccountError] = useState();
    const [accountDetails, setAccountDetails] = useState();
    const [accNumber, setAccNumber] = useState();
    // const [bankName, setBankName] = useState();
    // const [swiftCode, setSwiftCode] = useState();
    const [accountHolderName, setAccountHolderName] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isAddBankModalOpen, setIsAddBankModalOpen] = useState(false);

    useEffect(() => {
        getAccountDetails();
    }, []);

    const getAccountDetails = async () => {
        setIsEditingAccount(false);
        try {
            setIsLoading(true);
            const {data, status} = await Instance.get('/bank-detail');
            if (!data.accountNumber) return;
            const {accountHolderName, accountNumber} = data;
            setAccountDetails({accountHolderName, accountNumber});
        } catch (err) {
            // console.log(err.response)
        } finally {
            setIsLoading(false);
        }
    };

    // const createUpdateAccountToken = async () => {
    //     let tokenObj = {
    //         country: 'nz',
    //         currency: 'nzd',
    //         account_number: accNumber,
    //         account_holder_type: 'individual',
    //         account_holder_name: `${user.firstName} ${user.lastName}`,
    //     };
    //     // if (user.address.country === 'AU' || 'Australia') {
    //     //     tokenObj.routing_number = bsb
    //     //   }
    //     // if (bsb && accNumber.length < 10) tokenObj.routing_number = bsb
    //     try {
    //         const response = await stripe.createToken('bank_account', tokenObj);
    //         if (response.token) {
    //             setUpdateAccountError('');
    //             return response.token;
    //         } else {
    //             setUpdateAccountError('Invalid account number');
    //         }
    //     } catch (err) {
    //         setUpdateAccountError('Something went wrong');
    //     }
    // };

    const updateAccountDetails = async () => {
        setIsUpdateAccountLoading(true);
        let payload = {
            accountHolderName: accountHolderName,
            accountNumber: accNumber,
            // bankName: bankName,
            // swiftCode : swiftCode
        };
        try {
            await Instance.patch('/bank-detail', payload);
            await getAccountDetails();
        } catch (error) {
            errorDispatch({
                type: 'openSnackBar',
                data: {
                    message: 'Somthing went wrong !',
                    btnText: SNACKBAR_BUTTON_TYPES.CLOSE,
                    btnFunc: () => errorDispatch({type: 'closeSnackBar'}),
                },
            });
        } finally {
            setIsUpdateAccountLoading(false);
        }
    };

    const getAccountDetailsIcon = () => {
        return isEditingAccount ? (
            <Arrow
                width={40}
                height={40}
                onClick={() => setIsEditingAccount(!isEditingAccount)}
            />
        ) : (
            <EditIcon onClick={() => setIsEditingAccount(!isEditingAccount)} />
        );
    };
    const handleCallback = async () => {
        const {data} = await Instance.get('/users/me');
        dispatch({type: 'setUser', data});
    };
    return (
        <div className='AccountSettings__Container'>
            {accountDetails && (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div>
                        <div className='AccountSettings__UserName'>
                            Bank Details
                        </div>
                        <div className='AccountSettings__BodyText'>
                            Your saved Bank Account details.
                        </div>
                    </div>

                    <div>{getAccountDetailsIcon()}</div>
                </div>
            )}

            {isLoading ? (
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <CircularProgress color={'secondary'} />
                </div>
            ) : !accountDetails ? (
                <>
                    <Button
                        isLoading={false}
                        text='Add Bank Account'
                        inLineError
                        errorMessage={''}
                        onClick={() => setIsAddBankModalOpen(prev => !prev)}
                    />
                    <AddBankModel
                        isAddBankModalOpen={isAddBankModalOpen}
                        setIsAddBankModalOpen={setIsAddBankModalOpen}
                        getAccountDetails={handleCallback}
                    />
                </>
            ) : isEditingAccount ? (
                <>
                    <ValidationTextInput
                        label='Account Number'
                        onChange={e => {
                            let value = e.target.value?.replace(
                                /\s|[^0-9]/g,
                                '',
                            );
                            setAccNumber(value ? value : e.target.value);
                        }}
                        placeholder='1234 5678'
                        value={
                            accNumber
                                ? accNumber
                                : (accountDetails?.accountNumber ?? '')
                        }
                        minLength={15}
                        maxLength={16}
                    />
                    <ValidationTextInput
                        label='Account Holder Name'
                        onChange={e => setAccountHolderName(e.target.value)}
                        value={
                            accountHolderName
                                ? accountHolderName
                                : (accountDetails?.accountHolderName ?? '')
                        }
                        placeholder=''
                    />

                    {/* <ValidationTextInput
                        label='Bank Name'
                        onChange={e => setBankName(e.target.value)}
                        value={bankName}
                        placeholder=''
                    />
                      <ValidationTextInput
                        label='SWIFT/BIC'
                        onChange={e => setSwiftCode(e.target.value)}
                        value={swiftCode}
                        placeholder=''
                    /> */}

                    <Button
                        isLoading={isUpdateAccountLoading}
                        text='Update'
                        inLineError
                        errorMessage={updateAccountError}
                        onClick={updateAccountDetails}
                    />
                </>
            ) : (
                <div className='AccountCardContainer'>
                    <div className='AccountCardField'>
                        <span className='bank-details-heading'>
                            Account Number
                        </span>
                        <span>
                            {accountDetails?.accountNumber.replace(
                                /\d(?=\d{4})/g,
                                'X',
                            )}
                        </span>
                    </div>
                    <div className='AccountCardField'>
                        <span className='bank-details-heading'>
                            Account Holder Name
                        </span>
                        <span>{accountDetails?.accountHolderName}</span>
                    </div>
                    {/* <div className='AccountCardField'>
                        <span className='bank-details-heading'>Bank Name</span>
                        <span>{accountDetails.bankName}</span>
                    </div>
                    <div className='AccountCardField'>
                        <span className='bank-details-heading'>SWIFT/BIC</span>
                        <span>{accountDetails.swiftCode}</span>
                    </div> */}
                </div>
            )}
        </div>
    );
}
