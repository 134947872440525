import React from 'react';

export default function EditIcon({onClick}) {
    return (
        <div onClick={onClick} style={{cursor: 'pointer'}}>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='22.625'
                height='22.625'
                viewBox='0 0 22.625 22.625'
            >
                <g
                    id='Group_2555'
                    data-name='Group 2555'
                    transform='translate(-1529 -611.538)'
                >
                    <path
                        id='Path_747'
                        data-name='Path 747'
                        d='M20.013,28.753H13.49A8.06,8.06,0,0,1,5.439,20.7V14.179A8.06,8.06,0,0,1,13.49,6.128h3.262a1.15,1.15,0,0,1,0,2.3H13.49a5.758,5.758,0,0,0-5.751,5.751V20.7a5.758,5.758,0,0,0,5.751,5.751h6.524A5.757,5.757,0,0,0,25.764,20.7V17.441a1.15,1.15,0,0,1,2.3,0V20.7A8.06,8.06,0,0,1,20.013,28.753Z'
                        transform='translate(1523.561 605.41)'
                        fill='#ac172c'
                    />
                    <path
                        id='Path_748'
                        data-name='Path 748'
                        d='M17.6,22.936a2.184,2.184,0,0,1-2.1-2.74l.607-2.3a6.309,6.309,0,0,1,1.639-2.853L25.5,7.287a3.95,3.95,0,1,1,5.586,5.586L23.34,20.62a6.317,6.317,0,0,1-2.876,1.645l-2.3.6A2.212,2.212,0,0,1,17.6,22.936ZM28.3,8.431a1.644,1.644,0,0,0-1.167.482l-7.755,7.755a4.008,4.008,0,0,0-1.041,1.813l-.556,2.105,2.109-.547a4.016,4.016,0,0,0,1.827-1.045l7.748-7.748A1.648,1.648,0,0,0,28.3,8.431Z'
                        transform='translate(1519.38 605.406)'
                        fill='#ac172c'
                    />
                </g>
            </svg>
        </div>
    );
}
