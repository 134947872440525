import React from 'react';

export default function GreyTriangle() {
    return (
        <div className='ReceivedMessageTriangle'>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='19'
                height='41'
                viewBox='0 0 19 41'
            >
                <defs>
                    <clipPath id='clip-Grey_-_Message_bubble_triangle'>
                        <rect width='19' height='41' />
                    </clipPath>
                </defs>
                <g
                    id='Grey_-_Message_bubble_triangle'
                    data-name='Grey - Message bubble triangle'
                    clipPath='url(#clip-Grey_-_Message_bubble_triangle)'
                >
                    <path
                        id='Subtraction_36'
                        data-name='Subtraction 36'
                        d='M19.178,44.063H7.14A6.952,6.952,0,0,1,1.12,33.636L18.533,3.476a7.2,7.2,0,0,1,.645-.939Z'
                        transform='translate(-0.178 -2.816)'
                        fill='#ededed'
                    />
                </g>
            </svg>
        </div>
    );
}
