// Function to get location with Promise
export const getCurrentLatLong = () => {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    resolve({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                },
                (error) => {
                    reject('Error getting location: ' + error?.message);
                }
            );
        } else {
            reject('Geolocation is not supported by this browser.');
        }
    });
};