import {useEffect, useState} from 'react';
import './Disputes.css';
import DisputeService from '../../../services/disputes';
import MissingProfile from '../../../assets/Icons/MissingProfileIcon.png';
import getImage from '../../../util/getImage';
import {Avatar} from '@material-ui/core';
import useGlobalState from '../../../util/useGlobalState';
import ItemCard from '../../../components/itemCard/itemCard';
import {ReactComponent as StarFilled} from '../../../assets/Icons/StarFilled.svg';
import BookingCalculator from '../../../util/calculator/BookingCalculator';
import getBookingDuration from '../../../util/tradeUtils/getBookingDuration';
import TradeSidebarPaymentPanel from '../../../components/tradeLinearCalendar/TradeSidebarPaymentPanel';
import TradeSidebarExtendedPaymentPanel from '../../../components/tradeLinearCalendar/TradeSidebarExtendedPaymentPanel';
import BookingDatesPanel from '../../../components/BookingDatesPanel/BookingDatesPanel';
import LendingTimeline from './lending-timeline';
import Skeleton from '@mui/material/Skeleton';
import {CircularProgress} from '@material-ui/core';
import NoContent from '../../../components/NoContent/NoContent';

const Disputes = ({setAccountContent}) => {
    const [bookingPriceCalculator, setBookingPriceCalculator] = useState(
        new BookingCalculator(),
    );
    const [disputesList, setDisputesList] = useState([]);
    const [activeDispute, setActiveDispute] = useState({
        disputes: [],
        disputeStatus: '',
        bookingDurations: [],
        deliveryOption: '',
        item: {},
        pickupPrice: 0,
        bookingEvents: [],
    });
    const [isLoading, setIsLoading] = useState(false);

    const {state} = useGlobalState();
    const {user} = state;

    const {
        disputes,
        disputeStatus,
        bookingDurations,
        deliveryOption,
        item,
        deliveryPrice,
        pickupPrice,
        bookingEvents,
    } = activeDispute;

    if (Object.keys(activeDispute)?.length > 0) {
        var firstBookingDuration = getBookingDuration(bookingDurations);
        var isDeliverySelected =
            deliveryOption === 'BOTH' || deliveryOption === 'DELIVERY'
                ? true
                : false;
        var isPickupSelected =
            deliveryOption === 'BOTH' || deliveryOption === 'PICKUP'
                ? true
                : false;
    }

    useEffect(() => {
        if (!item || !firstBookingDuration) return;
        const bookingPriceCalculator = new BookingCalculator(
            new Date(firstBookingDuration?.startDate),
            new Date(firstBookingDuration?.endDate),
            deliveryPrice,
            pickupPrice,
            bookingDurations[0]?.itemPrice ?? 0,
            bookingDurations[0]?.discount ?? 0,
            isDeliverySelected,
            isPickupSelected,
        );
        setBookingPriceCalculator(bookingPriceCalculator);
    }, [activeDispute]);

    useEffect(() => {
        setIsLoading(true);
        getUserDisputes();
    }, []);

    const getUserDisputes = async () => {
        const res = await DisputeService.getUserDisputes();
        if (res.length > 0) {
            setActiveDispute(res[0]);
            setDisputesList(res);
        }
        setIsLoading(false);
    };

    const userProfileCard = userProfile => {
        return (
            <div className='deputes-detail-box'>
                <div className='user-list'>
                    <div className='user-profile'>
                        <Avatar
                            src={`${
                                userProfile?.avatar
                                    ? getImage(userProfile?.avatar)
                                    : MissingProfile
                            }`}
                            className='Dispute__Item_Avatar'
                            alt='ProfilePicture'
                        />
                    </div>
                    <div className='user-name'>
                        <h3>
                            {userProfile?.id === user.id
                                ? 'Me'
                                : userProfile?.firstName +
                                  ' ' +
                                  userProfile?.lastName}
                        </h3>
                        <p>B: {userProfile?.borrowerRating}/5</p>
                        <StarFilled />

                        {userProfile?.isLender && (
                            <>
                                <p>L: {userProfile?.lenderRating}/5</p>
                                <StarFilled />
                            </>
                        )}
                    </div>
                </div>
                <div className='dispute-user-role'>
                    {userProfile?.isLender ? 'Lender' : 'Borrower'}
                </div>
            </div>
        );
    };
    const renderUsers = () => {
        if (!disputes) return <div></div>;
        return (
            <div className='lender-borrowe-container'>
                <p>Disputee</p>
                {userProfileCard(disputes?.[0]?.disputee)}
                <p>Disputer</p>
                {userProfileCard(disputes?.[0]?.disputer)}
            </div>
        );
    };

    const convertStringToCapitalize = value => {
        const firstLetterCapitalized = value
            ?.toLowerCase()
            ?.replace('_', ' ')
            ?.split(' ')
            ?.map(word => word.charAt(0).toUpperCase() + word.slice(1))
            ?.join(' ');
        return firstLetterCapitalized;
    };

    return (
        <>
            {isLoading ? (
                <div className='dispute-overview-container'>
                    <CircularProgress color={'secondary'} />
                </div>
            ) : disputesList?.length === 0 ? (
                <div className='dispute-overview-container'>
                    <NoContent
                        header='No Disputes'
                        text='No active disputes are available.'
                        // buttonText='Search for an item'
                        // onButtonClick={() => history.push('/search')}
                    />
                </div>
            ) : (
                <>
                    <div className='Disputes__FlexMain'>
                        <div className='Disputes__Main'>
                            <div className='Disputes__Container'>
                                <div className='Disputes__Dispute_detail_Flex'>
                                    {/* {isLoading ? (
                    Array(7)
                      .fill(0)
                      ?.map(() => {
                        return (
                          <div className='Dispute-list-item'>
                            <div>
                              <Skeleton
                                variant='circular'
                                width={40}
                                height={40}
                              />
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                              <Skeleton
                                variant='text'
                                width={100}
                                sx={{ fontSize: '1rem' }}
                              />
                              <Skeleton
                                variant='text'
                                width={220}
                                sx={{ fontSize: '1rem' }}
                              />
                            </div>
                          </div>
                        )
                      })
                  ) : disputesList?.length > 0 ? ( */}
                                    {disputesList.map(dt => {
                                        let disputeBy = `${
                                            dt.disputes[0].disputerId ===
                                            user.id
                                                ? 'You'
                                                : dt.disputes[0]?.disputer
                                                      ?.firstName +
                                                  ' ' +
                                                  dt.disputes[0]?.disputer
                                                      ?.lastName
                                        }`;
                                        return (
                                            <div
                                                className={`${
                                                    activeDispute.id === dt.id
                                                        ? 'active'
                                                        : ''
                                                } Dispute-list-item`}
                                                onClick={() => {
                                                    setActiveDispute(dt);
                                                }}
                                            >
                                                <Avatar
                                                    src={`${
                                                        dt.item.images[0]
                                                            ? getImage(
                                                                  dt.item
                                                                      .images[0]
                                                                      .imageKey,
                                                              )
                                                            : MissingProfile
                                                    }`}
                                                    className='Dispute__Item_Avatar'
                                                    alt='ProfilePicture'
                                                />
                                                <div className='item-dispute-status'>
                                                    <h3>
                                                        {convertStringToCapitalize(
                                                            dt.disputeStatus &&
                                                                dt.disputeStatus !==
                                                                    'NONE'
                                                                ? dt.disputeStatus
                                                                : 'RAISED',
                                                        )}
                                                    </h3>
                                                    <p>
                                                        Disputed By{' '}
                                                        <b>{disputeBy}</b>
                                                    </p>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    {/* ) : (
                    <div className='justify-center'>No disputes found</div>
                  )} */}
                                </div>
                            </div>
                        </div>
                        <div className='Disputes__Detail'>
                            <div className='Disputes__Container'>
                                <div className='disputes-header'>
                                    <h4>Dispute Overview</h4>
                                    {disputeStatus && (
                                        <div
                                            className={`despute-status ${disputeStatus}`}
                                        >
                                            {convertStringToCapitalize(
                                                disputeStatus,
                                            )}
                                        </div>
                                    )}
                                </div>

                                <div className='dispute-info-card'>
                                    <div className='dispute-info-text'>
                                        <span className='font-bold'>
                                            {disputes[0]?.disputer?.firstName +
                                                ' ' +
                                                disputes[0]?.disputer?.lastName}
                                        </span>{' '}
                                        has disputed that{' '}
                                        <span className='font-bold'>
                                            {disputes[0]?.disputee?.firstName +
                                                ' ' +
                                                disputes[0]?.disputee?.lastName}
                                        </span>{' '}
                                        has not followed the LBS process.
                                    </div>
                                </div>

                                <div className='desputes-status'>
                                    <div className='status'>
                                        <h3>Status</h3>
                                        <p>
                                            {convertStringToCapitalize(
                                                disputeStatus,
                                            )}
                                        </p>
                                    </div>
                                    <div className='cost'>
                                        <h3>Lander settlement Amount</h3>
                                        <p>${disputes[0]?.lenderSettlement}</p>
                                    </div>
                                    <div className='cost'>
                                        <h3>borrower settlement Amount</h3>
                                        <p>
                                            ${disputes[0]?.borrowerSettlement}
                                        </p>
                                    </div>
                                </div>
                                <div className='lander-box'>
                                    <h3>Lending Users</h3>
                                    {renderUsers()}

                                    <div className='land-items-box'>
                                        <h3>Booking Details</h3>
                                        <div className='booking-details-card'>
                                            <div className='item-card-parent'>
                                                <ItemCard item={item} />
                                            </div>
                                            <div className='item-cost-card'>
                                                {bookingPriceCalculator &&
                                                    firstBookingDuration &&
                                                    item &&
                                                    (bookingDurations.length ===
                                                    1 ? (
                                                        <TradeSidebarPaymentPanel
                                                            bookingPriceCalculator={
                                                                bookingPriceCalculator
                                                            }
                                                            isDeliverySelected={
                                                                isDeliverySelected
                                                            }
                                                            isPickupSelected={
                                                                isPickupSelected
                                                            }
                                                            item={item}
                                                            selectedBooking={
                                                                activeDispute
                                                            }
                                                            selectedBookingDuration={
                                                                firstBookingDuration
                                                            }
                                                        />
                                                    ) : (
                                                        <TradeSidebarExtendedPaymentPanel
                                                            bookingPriceCalculator={
                                                                bookingPriceCalculator
                                                            }
                                                            isDeliverySelected={
                                                                isDeliverySelected
                                                            }
                                                            isPickupSelected={
                                                                isPickupSelected
                                                            }
                                                            item={item}
                                                            selectedBooking={
                                                                activeDispute
                                                            }
                                                            selectedBookingDuration={
                                                                firstBookingDuration
                                                            }
                                                            bookingDurations={
                                                                bookingDurations
                                                            }
                                                        />
                                                    ))}
                                            </div>
                                        </div>
                                        <div className='disputes-detail'>
                                            <h3>Disputes Details </h3>
                                            {disputes?.length > 0 && (
                                                <div className='disputes-details-box'>
                                                    <div className='d-details-list'>
                                                        <h3>Reasons</h3>
                                                        <ol>
                                                            {disputes?.[0]
                                                                ?.reason
                                                                ?.length > 0 &&
                                                                disputes?.[0]?.reason.map(
                                                                    (
                                                                        dt,
                                                                        index,
                                                                    ) => {
                                                                        return (
                                                                            <li>
                                                                                {
                                                                                    dt.reason
                                                                                }
                                                                            </li>
                                                                        );
                                                                    },
                                                                )}
                                                        </ol>
                                                    </div>
                                                    <div className='d-detail-gallery'>
                                                        <h3>Comment</h3>
                                                        <p>
                                                            {
                                                                disputes?.[0]
                                                                    ?.comment
                                                            }
                                                        </p>
                                                        {disputes?.[0]
                                                            ?.attachments
                                                            ?.length > 0 && (
                                                            <>
                                                                <h3>
                                                                    Attachment
                                                                </h3>
                                                                <div className='d-detail-images'>
                                                                    {disputes[0]?.attachments.map(
                                                                        (
                                                                            dt,
                                                                            index,
                                                                        ) => {
                                                                            return (
                                                                                <div className='d-detail-thumb'>
                                                                                    <img
                                                                                        src={getImage(
                                                                                            dt.imageKey,
                                                                                        )}
                                                                                    />
                                                                                </div>
                                                                            );
                                                                        },
                                                                    )}
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className='booking-date'>
                                            <h3>Dates</h3>
                                            {activeDispute &&
                                                firstBookingDuration && (
                                                    <BookingDatesPanel
                                                        startDate={
                                                            new Date(
                                                                firstBookingDuration.startDate,
                                                            )
                                                        }
                                                        endDate={
                                                            new Date(
                                                                firstBookingDuration.endDate,
                                                            )
                                                        }
                                                    />
                                                )}
                                        </div>
                                    </div>
                                    <div className='pt-3'>
                                        <h3>Lending Events</h3>
                                        <div className='disputes-lending'>
                                            <LendingTimeline
                                                events={bookingEvents}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default Disputes;
