export default function reducer(state, action) {
    switch (action.type) {
        case 'setUser': {
            return {
                ...state,
                user: action.data,
            };
        }
        case 'setUnReadMessageCount': {
            return {
                ...state,
                unReadMessageCount: action.data,
            };
        }
        case 'setUnReadNotificationsCounts': {
            return {
                ...state,
                unReadNotificationsCounts: action.data,
            };
        }
        case 'setUserBlockedAvailability': {
            return {
                ...state,
                user: {
                    ...state.user,
                    userBlockedAvailability: action.data,
                },
            };
        }
        case 'setIsSentEmailCode': {
            return {
                ...state,
                isSentEmailCode: action.data,
            };
        }
        case 'setIsEmailCodeVarified': {
            return {
                ...state,
                isEmailCodeVarified: action.data,
            };
        }
        case 'setCurrentLatLong': {
            return {
                ...state,
                currentLatLong: action.data,
            };
        }
        default:
            return state;
    }
}
