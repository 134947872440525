import {useEffect, useState} from 'react';
import './itemCard.css';
import NoContentImage from '../../assets/Images/NoContent.png';
import LocationIcon from './../../assets/Icons/LocationIcon.svg';
import DeliveryIcon from './../../assets/Icons/DeliveryIcon.svg';
// import {ReactComponent as StarOutline} from './../../assets/Icons/StarOutline.svg';
import {ReactComponent as StarFilled} from './../../assets/Icons/StarFilled.svg';
import {Link} from 'react-router-dom';
import getImage from '../../util/getImage';
import useGlobalState from '../../util/useGlobalState';
import RatingFiller from '../ratingFiller/ratingFiller';
export default function ItemCard({item, favourited}) {
    const [itemImage, setItemImage] = useState('');
    const [itemSuburb, setItemSuburb] = useState('');
    const {state} = useGlobalState();
    // const {user} = state;

    useEffect(() => {
        if (item?.images?.length > 0) {
            setItemImage(item.images[0]?.imageKey);
        }
        if (item?.address?.suburb) {
            setItemSuburb(item.address?.suburb);
        }
    }, [item]);

    return (
        <div className='ItemCard'>
            <div className='product-list'>
                <Link
                    // to={user ? `/item/${item.id}` : '/search'}
                    to={`/item/${item.id}`}
                    style={{
                        position: 'relative',
                        // cursor: user ? 'pointer' : 'not-allowed',
                        cursor: 'pointer',
                    }}
                >
                    <img
                        src={itemImage ? getImage(itemImage) : NoContentImage}
                        alt={item.title}
                        className='PreviewImage'
                    />
                    {favourited && (
                        <div className='favouriteStar'>
                            <StarFilled />
                        </div>
                    )}
                </Link>
                <div className='ItemDetailsContainer'>
                    <div className='ItemNameText'>{item.title}</div>
                    <div className='price-rating'>
                        <div className='ItemPriceText'>${item.price}</div>
                        <div className='RatingSection'>
                            {item.rating ? (
                                <>
                                    <span className='ItemRateText'>
                                        {item.rating}/5
                                    </span>
                                    <div
                                        className='RatingStars'
                                        style={{marginLeft: 0}}
                                    >
                                        <RatingFiller rating={item.rating} />
                                    </div>
                                </>
                            ) : (
                                <div className='not-rating-yet'>
                                    No ratings yet
                                </div>
                            )}
                        </div>
                    </div>

                    {/* <div className='StatusLocationSection'>
                        <div className='ItemCardIconContainer'>
                            <img
                                src={DeliveryIcon}
                                alt=''
                                className='ItemCardIcon'
                            />
                        </div>
                        {item.deliveryOption !== 'NONE'
                            ? 'Available'
                            : 'Unavailable'}
                    </div> */}
                    <div className='StatusLocationSection'>
                        <div className='ItemCardIconContainer'>
                            <img
                                src={LocationIcon}
                                alt={itemSuburb}
                                className='ItemCardIcon'
                            />
                        </div>
                        <div
                            style={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {itemSuburb}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
