import React from 'react';
import PageWrapper from '../../components/pageWrapper/pageWrapper';

const ExtendBookingApplication = () => {
    return (
        <PageWrapper>
            <div className='ApplicationContainer'>Extend Me</div>
        </PageWrapper>
    );
};

export default ExtendBookingApplication;
