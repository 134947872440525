import React, {useState} from 'react';
import './UserButton.css';
import MissingProfile from '../../assets/Icons/MissingProfileIcon.png';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {ReactComponent as Trades} from '../../assets/Icons/Trades.svg';
import {ReactComponent as Messages} from '../../assets/Icons/Messages.svg';
import {ReactComponent as YourShed} from '../../assets/Icons/YourShed.svg';
import {ReactComponent as PostItem} from '../../assets/Icons/PostItem.svg';
import {ReactComponent as Favourites} from '../../assets/Icons/FavouritesIcon.svg';
import {ReactComponent as Account} from '../../assets/Icons/Account.svg';
import {CiBellOn} from 'react-icons/ci';
import {Link} from 'react-router-dom';
import useGlobalState from '../../util/useGlobalState';
import getImage from '../../util/getImage';
import {Avatar, Badge, styled} from '@material-ui/core';
import {isMobile} from 'react-device-detect';
import {CometChat} from '@cometchat-pro/chat';
import {useHistory} from 'react-router';
import Button from '../Button/Button';

const StyledBadge = styled(Badge)(({theme}) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#b03b4b',
        color: '#b03b4b',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            border: '1px solid currentColor',
            content: '""',
        },
    },
}));

export default function UserButton() {
    const {state, dispatch} = useGlobalState();
    const {user, unReadMessageCount, unReadNotificationsCounts} = state;
    const firstName = user.firstName;
    const history = useHistory();

    const [menuOpen, setMenuOpen] = useState(false);

    // const lendingRoute = path => {
    //     window.location.href = `${process.env.REACT_APP_LENDING_PAGE}${path}`;
    // };

    const handleLogout = async () => {
        try {
            localStorage.removeItem('LBSToken');
            dispatch({type: 'setUser', data: null});
            await CometChat.logout();
            // lendingRoute('/');
            history.push({ pathname: '/login' })
        } catch (error) {
            console.log('error', error);
        }
    };

    let notificationCount =
        unReadNotificationsCounts > 9 ? '9+' : unReadNotificationsCounts;
    return (
        <div className='UserButton__Container'>
            <button
                className={`UserButton ${menuOpen ? 'UserButton--Active' : ''}`}
                onClick={() => setMenuOpen(!menuOpen)}
            >
                {unReadMessageCount > 0 ? (
                    <StyledBadge
                        overlap='circular'
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        variant='dot'
                    >
                        <Avatar
                            src={`${!user.avatar ? MissingProfile : getImage(user.avatar)}`}
                            className='UserButton_ProfilePicture'
                            alt='ProfilePicture'
                        />
                    </StyledBadge>
                ) : (
                    <Avatar
                        src={`${!user.avatar ? MissingProfile : getImage(user.avatar)}`}
                        className='UserButton_ProfilePicture'
                        alt='ProfilePicture'
                    />
                )}
                {!isMobile && (
                    <div className='UserButton__FirstName'>{firstName}</div>
                )}
                <KeyboardArrowDownIcon />
            </button>

            <div
                className={`UserButton__Menu__Wrapper header-menu ${
                    menuOpen
                        ? 'UserButton__Menu--Active header-menu-active'
                        : ''
                }`}
            >
                <div
                    className={`UserButton__Menu`}
                    onClick={e => e.stopPropagation()}
                >
                    <ul>
                        <li>
                            <Link to='/user/trades'>
                                <div className='menu-icon'>
                                    <Trades height='24px' width='24px' />
                                </div>
                                Trades
                            </Link>
                        </li>

                        <li>
                            <Link to='/user/messages'>
                                <div className='menu-icon'>
                                    <Messages height='24px' width='24px' />
                                </div>
                                Messages
                            </Link>
                        </li>

                        {user.isLender && (
                            <>
                                <li>
                                    <Link to='/user/your_shed'>
                                        <div className='menu-icon'>
                                            <YourShed
                                                height='24px'
                                                width='24px'
                                            />
                                        </div>
                                        Your Shed
                                    </Link>
                                </li>

                                <li>
                                    <Link to='/postitem'>
                                        <div className='menu-icon'>
                                            <PostItem
                                                height='24px'
                                                width='24px'
                                            />
                                        </div>
                                        Post an Item
                                    </Link>
                                </li>
                            </>
                        )}

                        <li>
                            <Link to='/user/favourites'>
                                <div className='menu-icon'>
                                    <Favourites height='24px' width='24px' />
                                </div>
                                Favourites
                            </Link>
                        </li>

                        <li>
                            <Link to='/user/notifications'>
                                <div
                                    className='menu-icon'
                                    style={{position: 'relative'}}
                                >
                                    <CiBellOn
                                        height='24px'
                                        width='24px'
                                        className='notification_Icon_header'
                                    />
                                    {notificationCount != 0 && (
                                        <div className='notification_count'>
                                            {notificationCount}
                                        </div>
                                    )}
                                </div>
                                Notifications
                            </Link>
                        </li>

                        <li>
                            <Link to='/user/account'>
                                <div className='menu-icon'>
                                    <Account height='24px' width='24px' />
                                </div>
                                Account
                            </Link>
                        </li>

                        {/* {isMobile && ( */}
                        <li>
                            <button
                                className='LogoutButton UserButtonLogout'
                                onClick={() => handleLogout()}
                            >
                                Logout
                            </button>
                        </li>
                        {/* )} */}
                    </ul>
                </div>
            </div>

            {menuOpen ? (
                <div
                    className='UserButton__Menu__CloseWrapper'
                    onClick={() => setMenuOpen(false)}
                />
            ) : (
                ''
            )}
        </div>
    );
}
