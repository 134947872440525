import {useState} from 'react';
import {CircularProgress, DialogContent, IconButton} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {makeStyles} from '@material-ui/styles';
import {ReactComponent as StarOutline} from './../../../assets/Icons/StarOutline.svg';
import {ReactComponent as StarFilled} from './../../../assets/Icons/StarFilled.svg';
import {Dialog} from '@material-ui/core';
import Button from '../../Button/Button';
import axios from 'axios';
import {Booking, RateLenderInfo} from '../../../types/Booking';
import BookingService from '../../../services/booking';

type Props = {
    booking: Booking | null;
    isOpen: boolean;
    onClose: () => void;
    setSelectedBooking: React.Dispatch<React.SetStateAction<null | Booking>>;
    getBookings: () => Promise<void>;
};

function ReviewLender({
    onClose,
    booking,
    isOpen,
    getBookings,
    setSelectedBooking,
}: Props) {
    const [comment, setComment] = useState('');
    const [lenderRating, setLenderRating] = useState(1);
    const [lenderComment, setLenderComment] = useState('');
    const [productRating, setProductRating] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isLenderRated, setIsLenderRated] = useState(false);
    const [isItemRated, setIsItemRated] = useState(false);
    const useStyles = makeStyles({
        button: {
            backgroundColor: '#B43B4C',
            '&:hover': {
                backgroundColor: '#cf3247',
            },
        },
        icon: {
            color: '#FFF',
        },
        buttonDelete: {
            position: 'absolute',
            top: -20,
            right: -20,
            width: 40,
            height: 40,
            backgroundColor: '#B43B4C',
            '&:hover': {
                backgroundColor: '#cf3247',
            },
        },
    });

    const submitReview = async () => {
        try {
            if (!booking) return;
            setIsLoading(true);
            const ratingInfo: RateLenderInfo = {
                lenderRating: {
                    lenderId: booking.item.userId,
                    borrowerId: booking.borrowerId,
                    rating: lenderRating,
                    comment: lenderComment,
                },
                itemRating: {
                    itemId: booking.itemId,
                    comment: comment,
                    rating: productRating,
                    userId: booking.borrowerId,
                    bookingId: booking.id,
                },
            };
            await BookingService.rateLender(booking.id, ratingInfo);
            let getSelectedBookinng = await BookingService.getBookingById(
                booking.id,
            );
            setSelectedBooking(getSelectedBookinng);
            getBookings();
            onClose();
        } catch (err) {
            if (axios.isAxiosError(err)) {
                // console.log(err.response?.data)
            }
        } finally {
            setIsLoading(false);
        }
    };

    const setItemRating = (rating: number) => {
        setProductRating(rating);
        setIsItemRated(true);
    };
    const setNewLenderRating = (rating: number) => {
        setLenderRating(rating);
        setIsLenderRated(true);
    };

    const renderLenderStars = () => {
        const starArray = new Array(5).fill(null);
        return starArray.map((_, index) => {
            if (index + 1 <= lenderRating) {
                return (
                    <StarFilled
                        className='BorrowerStarIcon StarClick'
                        fill='#E9D8B4'
                        onClick={() => setNewLenderRating(index + 1)}
                        key={index}
                    />
                );
            } else {
                return (
                    <StarOutline
                        className='BorrowerStarIcon StarClick'
                        fill='#E9D8B4'
                        onClick={() => setNewLenderRating(index + 1)}
                        key={index}
                    />
                );
            }
        });
    };

    const renderProductStars = () => {
        const starArray = new Array(5).fill(null);
        return starArray.map((_, index) => {
            if (index + 1 <= productRating) {
                return (
                    <StarFilled
                        className='BorrowerStarIcon StarClick'
                        fill='#E9D8B4'
                        onClick={() => setItemRating(index + 1)}
                        key={index}
                    />
                );
            } else {
                return (
                    <StarOutline
                        className='BorrowerStarIcon StarClick'
                        fill='#E9D8B4'
                        onClick={() => setItemRating(index + 1)}
                        key={index}
                    />
                );
            }
        });
    };

    const classes = useStyles();

    return (
        <Dialog open={isOpen} onClose={onClose} className='cus-modal'>
            <DialogContent className='BorrowerMain'>
                <div className='BorrowerHeaderContent'>
                    <div
                        className='BorrowerHeader'
                        style={{justifyContent: 'center'}}
                    >
                        Trade Complete
                    </div>
                    <IconButton
                        aria-label='delete'
                        className={classes.button}
                        onClick={onClose}
                    >
                        <Close className={classes.icon} />
                    </IconButton>
                </div>
                <div className='BorrowerHeader'>Lender Rating</div>
                <div className='BorrowerStarsFlex' style={{paddingTop: 15}}>
                    {renderLenderStars()}
                </div>
                <div className='BorrowerHeader'>Lender Comments</div>
                <textarea
                    rows={10}
                    maxLength={254}
                    placeholder={`Your comments on the lender.`}
                    value={lenderComment}
                    className='LoginInput PostItem__TextArea'
                    onChange={e => setLenderComment(e.target.value)}
                />
                <div className='BorrowerHeader'>Item Rating</div>
                <div className='BorrowerStarsFlex' style={{paddingTop: 15}}>
                    {renderProductStars()}
                </div>
                <div className='BorrowerHeader'>Item Comments</div>
                <textarea
                    rows={10}
                    maxLength={254}
                    placeholder={`Your comments on the item.`}
                    value={comment}
                    className='LoginInput PostItem__TextArea'
                    onChange={e => setComment(e.target.value)}
                />
                <div
                    className='ItemButtons'
                    style={{
                        justifyContent: 'center',
                        minHeight: 70,
                        marginTop: 20,
                    }}
                >
                    {isLoading ? (
                        <CircularProgress color={'secondary'} />
                    ) : (
                        <Button
                            text='Submit Review'
                            onClick={submitReview}
                            isDisabled={!(isLenderRated && isItemRated)}
                        />
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default ReviewLender;
