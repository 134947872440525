import React from 'react';
import {User} from '../../types/User';
import getImage from '../../util/getImage';
import RatingFiller from '../ratingFiller/ratingFiller';

type Props = {
    user: User;
};

function UserProfileCard({user}: Props) {
    return (
        <section className='mb-4'>
            <div className='text-center'>
                <div className='w-[150px] h-[150px] rounded-[50%] overflow-hidden mx-auto'>
                    <img
                        src={getImage(user.avatar)}
                        className='w-[100%] h-[100%]'
                    />
                </div>
                <div className='pt-5 w-full text-center'>
                    <p className='text-blue-base text-[20px] mb-1 font-bold w-full text-center'>
                        {user.firstName} {user.lastName}
                    </p>
                    <div className='text-[17px] flex gap-1 justify-center items-center flex-wrap'>
                        {user.lenderRating ? (
                            <>
                                {' '}
                                <span className='font-medium'>
                                    Lender:
                                </span>{' '}
                                {user.lenderRating}
                                /5 <RatingFiller
                                    rating={user.lenderRating}
                                />{' '}
                            </>
                        ) : (
                            <div>No ratings yet</div>
                        )}
                    </div>
                    <div className='text-[17px] flex gap-1 justify-center items-center flex-wrap'>
                        {user.borrowerRating ? (
                            <>
                                <span className='font-medium'>Borrower:</span>{' '}
                                {user.borrowerRating}/5{' '}
                                <RatingFiller rating={user.borrowerRating} />{' '}
                            </>
                        ) : (
                            <div>No ratings yet</div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default UserProfileCard;
