import React, {useState} from 'react';
import './MenuModal.css';
import {Modal, Box} from '@material-ui/core';
import {Link, useHistory} from 'react-router-dom';
import {IoClose} from 'react-icons/io5';
import {IoIosArrowUp, IoIosArrowDown} from 'react-icons/io';

const MenuModal = ({open, handleClose}) => {
    const [isInfoOpen, setIsInfoOpen] = useState(false);
    const history = useHistory();
    const lendingRoute = path => {
        window.location.href = `${process.env.REACT_APP_LENDING_PAGE}${path}`;
    };
    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                className='modal_box'
                style={{width: '30em', maxWidth: '50em'}}
            >
                <IoClose className='modal_close_icon' onClick={handleClose} />
                <Link to='/lend_your_stuff' className='modal_text'>
                    Lend Your Stuff
                </Link>
                <Link to='/rent_stuff' className='modal_text'>
                    Rent Stuff
                </Link>
                <Link
                    onClick={() => lendingRoute('/howitswork')}
                    to=''
                    className='modal_text'
                >
                    How It Works
                </Link>
                <Link
                    onClick={() => lendingRoute('/about_us')}
                    to=''
                    className='modal_text'
                >
                    About Us
                </Link>
                <div
                    className='modal_info_hub_container'
                    onClick={() => setIsInfoOpen(!isInfoOpen)}
                >
                    Info Hub
                    {isInfoOpen ? (
                        <IoIosArrowUp
                            className='link_title_icon'
                            onClick={() => setIsInfoOpen(false)}
                        />
                    ) : (
                        <IoIosArrowDown
                            className='link_title_icon'
                            onClick={() => setIsInfoOpen(true)}
                        />
                    )}
                </div>
                <div
                    className={
                        isInfoOpen ? 'modal_popup_opend' : 'modal_popup_closed'
                    }
                >
                    <p
                        className='option_blog'
                        onClick={() => lendingRoute('/blog')}
                    >
                        Blog
                    </p>
                    <p
                        className='option_protection'
                        onClick={() => lendingRoute('/lender_protection')}
                    >
                        Lender Protection
                    </p>
                    <p
                        className='option_faqs'
                        onClick={() => lendingRoute('/faqs')}
                    >
                        FAQs for sharing
                    </p>
                </div>
            </Box>
        </Modal>
    );
};

export default MenuModal;
