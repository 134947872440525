export default function ShowPasswordIcon({onClick}) {
    return (
        <div onClick={onClick} style={{cursor: 'pointer'}}>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='19.999'
                height='16'
                viewBox='0 0 19.999 16'
            >
                <path
                    id='solid_eye-slash'
                    data-name='solid eye-slash'
                    d='M10,12.5a4.483,4.483,0,0,1-4.466-4.16L2.258,5.8A10.415,10.415,0,0,0,1.11,7.541a1.011,1.011,0,0,0,0,.912A10.023,10.023,0,0,0,10,14a9.707,9.707,0,0,0,2.434-.327l-1.622-1.255A4.5,4.5,0,0,1,10,12.5Zm9.807,1.816-3.455-2.67a10.351,10.351,0,0,0,2.539-3.19,1.011,1.011,0,0,0,0-.912A10.023,10.023,0,0,0,10,2,9.63,9.63,0,0,0,5.4,3.175L1.422.1A.5.5,0,0,0,.72.19L.107.98a.5.5,0,0,0,.088.7l18.386,14.21a.5.5,0,0,0,.7-.088l.614-.79A.5.5,0,0,0,19.808,14.312ZM14.067,9.875l-1.228-.949A2.961,2.961,0,0,0,9.21,5.115,1.489,1.489,0,0,1,9.5,6a1.458,1.458,0,0,1-.048.312l-2.3-1.778A4.447,4.447,0,0,1,10,3.5,4.5,4.5,0,0,1,14.5,8a4.393,4.393,0,0,1-.434,1.878Z'
                    transform='translate(-0.002 0.003)'
                />
            </svg>
        </div>
    );
}
