import React from 'react';
import {Link} from 'react-router-dom';
import {Booking, BookingDuration} from '../../../types/Booking';
import {UserTradeData} from '../../../types/User';
import EndDateCard from '../EndDateCard';
import StatusButton from '../tradeCalendarStatusPanel/StatusButton';
import moment from 'moment';

type Props = {
    isLender: boolean;
    userDetails: UserTradeData;
    endDate: string;
    bookingDuration: BookingDuration;
    selectedBooking: Booking;
};

const StatusExtensionApproved = ({
    isLender,
    userDetails,
    endDate,
    selectedBooking,
    bookingDuration,
}: Props) => {
    const isAfterEndDate = moment().isSameOrAfter(bookingDuration.endDate);
    const isDisputed = selectedBooking.disputes.some(
        dispute => !dispute.isResolved,
    );

    return (
        <div className='flex flex-col items-center p-2 text-center'>
            {isLender ? (
                <div>
                    <span>
                        You have approved{' '}
                        {`${userDetails.firstName} ${userDetails.lastName}`}'s
                        booking extension.
                    </span>
                    <div className='mb-2' />
                    <EndDateCard endDate={endDate} isLender={isLender} />
                </div>
            ) : (
                <>
                    <div>
                        <span className='mb-3'>
                            {`${userDetails.firstName} ${userDetails.lastName}`}{' '}
                            has approved your extension.
                        </span>
                        <div className='mb-2' />
                        <EndDateCard endDate={endDate} isLender={isLender} />
                    </div>
                    {!isAfterEndDate && !isDisputed && (
                        <div className='mt-2 w-full'>
                            <Link
                                className='w-full'
                                to={{
                                    pathname: `/item/${selectedBooking.itemId}/application`,
                                    state: {
                                        bookingDuration: bookingDuration,
                                        deliveryCosts: {
                                            deliveryPrice:
                                                selectedBooking.deliveryPrice,
                                            pickupPrice:
                                                selectedBooking.pickupPrice,
                                        },
                                    },
                                }}
                                replace
                            >
                                <StatusButton
                                    type='blue'
                                    text={
                                        <p
                                            style={{
                                                fontWeight: 'bold',
                                                margin: '0',
                                                color: 'white',
                                            }}
                                        >
                                            Extend Borrow
                                        </p>
                                    }
                                    width='100%'
                                />
                            </Link>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default StatusExtensionApproved;
