import React from 'react';

export default function Check() {
    return (
        <div>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='100%'
                height='100%'
                viewBox='0 0 100 100'
            >
                <defs>
                    <clipPath id='clip-Check'>
                        <rect width='100' height='100' />
                    </clipPath>
                </defs>
                <g id='Check' clipPath='url(#clip-Check)'>
                    <path
                        id='Union_58'
                        data-name='Union 58'
                        d='M24.424,59.3l0,0L13.956,48.842,0,34.885,10.47,24.419,24.424,38.373,62.8,0,73.26,10.468,24.426,59.3Z'
                        transform='translate(13 20.001)'
                        fill='#fff'
                    />
                </g>
            </svg>
        </div>
    );
}
