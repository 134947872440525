import React from 'react';

const FooterCopyRightComonent = () => {
    return (
        <div className='footer_bottom_container pt-2 pb-2'>
            <p className='footer_bottom_left'>
                <a href='mailto:support@littlebigshed.co.nz'>Support Team</a>
            </p>
            <p className='footer_bottom_right'>
                Copyright 2024 Little Big Shed
            </p>
        </div>
    );
};

export default FooterCopyRightComonent;
