import React from 'react';
import {Dialog} from '@material-ui/core';
import {DialogContent} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
export default function LenderCapabilitiesModal({isModalOpen, setIsModalOpen}) {
    return (
        <Dialog
            className='test'
            open={isModalOpen}
            onClose={() => setIsModalOpen(!isModalOpen)}
        >
            <DialogContent className='lenderCapabilitiesModalContainer'>
                <div className='lenderCapabilitiesModalTextContainer'>
                    <div className='lenderCapabilitiesModalHeading'>
                        Lender Capabilities
                    </div>
                    <button className='lenderCapabilitiesCloseBtn'>
                        <CloseIcon
                            onClick={() => setIsModalOpen(!isModalOpen)}
                        />
                    </button>
                    <div className='lenderCapabilitiesModalSubHeading'>
                        Borrow Account
                    </div>
                    <div className='CapabilitiesModalText'>
                        Borrow accounts are designed for user that soley want to
                        borrow items on Little Big Shed. This means less details
                        are required from you in order to make an account and
                        get started.
                    </div>
                    <div className='CapabilitiesModalText'>
                        If you wish to become a Little Big Shed lender in the
                        future you can always upgrade your account in the
                        settings tab in order to start lending out items.
                    </div>
                    <div className='lenderCapabilitiesModalSubHeading'>
                        Lender Account
                    </div>
                    <div className='CapabilitiesModalText'>
                        Lenders have access to everything on Little Big Shed
                        with the ability to post items as well as borrow items
                        from the platform.
                    </div>
                    <div className='CapabilitiesModalText'>
                        More information is required to become a lender, such
                        as; Bank deposit details, availability times for booking
                        items as well as some location details to help borrowers
                        search for the best items.
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
