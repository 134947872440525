import {useState, useEffect} from 'react';
import './notifications.css';
import PageWrapper from '../../components/pageWrapper/pageWrapper';
import UserShedNav from '../../components/UserShedNav/UserShedNav';
import useGlobalState from '../../util/useGlobalState';
import {isMobile} from 'react-device-detect';
import NoContent from '../../components/NoContent/NoContent';
import {useHistory} from 'react-router';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import moment from 'moment';
import {CircularProgress} from '@material-ui/core';
import Instance from '../../util/axios';

export const Notifications = () => {
    const {state, dispatch} = useGlobalState();
    const {unReadNotificationsCounts} = state;
    const history = useHistory();
    const [accountContent, setAccountContent] = useState('Messages');
    const [isLoading, setIsLoading] = useState(true);
    const [unReadNotifiactions, setUnReadNotifications] = useState([]);

    useEffect(() => {
        getUnReadNotifications();
    }, []);

    const getUnReadNotifications = async () => {
        try {
            const {data} = await Instance.get(
                `/notification-history?offset=0&limit=${
                    unReadNotificationsCounts ? unReadNotificationsCounts : 100
                }`,
            );

            setUnReadNotifications(data?.data);
        } catch (error) {
            // console.log({ error })
        } finally {
            setIsLoading(false);
        }
    };
    const markAsUnRead = async id => {
        try {
            const {data} = await Instance.patch(`/notification-history/${id}`);

            dispatch({
                type: 'setUnReadNotificationsCounts',
                data: unReadNotificationsCounts - 1,
            });
        } catch (error) {
            // console.log({ error })
        }
    };

    const handleRedirect = notificationData => {
        history.push({
            pathname: `/user/trades`,
            state: {bookingId: notificationData.actionId},
        });
        markAsUnRead(notificationData.id);
    };

    const renderCards = () => {
        return unReadNotifiactions?.map((data, index) => {
            return (
                <div
                    onClick={() => handleRedirect(data)}
                    key={index}
                    className='notificationCard'
                >
                    <div>
                        <span className='notification_title'>{data.title}</span>
                        <span className='notification_body'>{data.body}</span>
                        <span className='notification_time'>
                            {moment(data.createdAt).format('LLLL')}
                        </span>
                    </div>
                    <div>
                        <KeyboardArrowRightIcon />
                    </div>
                </div>
            );
        });
    };

    // const getSearchPages = () => {
    //   let content = []
    //   for (let i = 1; i < numSearchPages + 1; i++) {
    //     content.push(
    //       <div
    //         className={`PaginationPage ${
    //           searchPage === i ? 'PaginationPageActive' : ''
    //         }`}
    //         onClick={() => setSearchPage(i)}
    //         key={i}
    //       >
    //         {i}
    //       </div>
    //     )
    //   }
    //   return content
    // }

    // const handlePaginationButtonClick = direction => {
    //   if (direction === 'forward') {
    //     searchPage === numSearchPages
    //       ? setSearchPage(1)
    //       : setSearchPage(searchPage + 1)
    //   } else {
    //     searchPage === 1
    //       ? setSearchPage(numSearchPages)
    //       : setSearchPage(searchPage - 1)
    //   }
    // }
    return (
        <PageWrapper>
            <div className='UserShedWrapper'>
                {!isMobile && (
                    <UserShedNav
                        setAccountContent={setAccountContent}
                        accountContent={accountContent}
                    />
                )}
                <div
                    className='NotificationContentContainer'
                    style={
                        isLoading
                            ? {
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                              }
                            : null
                    }
                >
                    {isLoading ? (
                        <CircularProgress color={'secondary'} />
                    ) : unReadNotifiactions &&
                      unReadNotifiactions?.length === 0 ? (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                            }}
                        >
                            <NoContent
                                header='No Unread Notifications'
                                text='You currently do not have any unread Notifications'
                                buttonText='Search for an item'
                                onButtonClick={() => history.push('/search')}
                            />
                        </div>
                    ) : (
                        <>
                            <div className='containerParent'>
                                <div className='UserNotificationCardContainer'>
                                    <span className='MessagesHeading'>{`Notifications(${unReadNotifiactions?.length})`}</span>
                                    <div className="px-3">
                                        <div className='notification_container'>
                                            {renderCards()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='PaginationSection'>
                <div className='PagesText'>Pages</div>
                <div className='PaginationFlex'>
                  <div
                    className='PaginationArrow'
                    onClick={() => handlePaginationButtonClick('backward')}
                  >
                    {'<'}
                  </div>
                  {getSearchPages()}
                  <div
                    className='PaginationArrow'
                    onClick={() => handlePaginationButtonClick('forward')}
                  >
                    {'>'}
                  </div>
                </div>
              </div> */}
                        </>
                    )}
                </div>
            </div>
        </PageWrapper>
    );
};

export default Notifications;
