import {CometChat} from '@cometchat-pro/chat';
import axios from 'axios';
import {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {SNACKBAR_BUTTON_TYPES} from '../../assets/Data/LBSEnum';
import {BookingContext} from '../../pages/application/Application';
import BookingService from '../../services/booking';
import {CreateBooking} from '../../types/Booking';
import {Item} from '../../types/Item';
import Instance, {CometChatInstance} from '../../util/axios';
import {bookingPrice} from '../../util/calculator/calculations';
import getDeliveryOption from '../../util/getDeliveryOption';
import useErrorState from '../../util/reducers/errorContext';
import useGlobalState from '../../util/useGlobalState';
import Button from '../Button/Button';
import AddCardModel from '../modals/AddBankModel/AddCardModel';
import AgreementModal from '../modals/AgreementModal/AgreementModal';
import ApplicationItemCard from './ApplicationItemCard';
import BorrowApplicationCosts from './BorrowApplicationCosts';
import BorrowOverviewFooter from './BorrowOverviewFooter';
import ExtendApplicationCosts from './ExtendApplicationCosts';
import ExtendOverviewFooter from './ExtendOverviewFooter';
import './ItemOverview.css';

export default function ItemOverview() {
    const [isLoading, setIsLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [addCardOpen, setAddCardOpen] = useState(false);
    const {state, dispatch} = useContext(BookingContext);
    const {errorDispatch} = useErrorState();
    const globalState = useGlobalState();
    const user = globalState.state.user;

    const {
        item,
        startDate,
        endDate,
        isDeliverySelected,
        isPickupSelected,
        borrowerAddress,
        bookingCalculator,
        mode,
        appliedEndDate,
        bookingDuration,
        appliedStartDate,
        rescheduleBookingId,
    } = state;

    const history = useHistory();

    // const extensionPrice = calculateExtensionPrice({
    //   bookingCalculator,
    //   bookingDuration,
    // })
    const extensionPrice = bookingPrice(
        appliedStartDate,
        endDate,
        item,
        bookingDuration,
    );

    // Set borrower address if address has not been set
    const setBorrowerAddressFallback = () =>
        dispatch({
            type: 'setBorrowerAddress',
            data: item.address,
        });

    useEffect(() => {
        if (!borrowerAddress) {
            setBorrowerAddressFallback();
        }
        return;
    }, []);

    const saveBooking = async () => {
        const bookingInfo = getBookingInfo();
        try {
            setIsLoading(true);
            await makeBooking(bookingInfo, item);
        } catch (error) {
            errorDispatch({
                type: 'openSnackBar',
                data: {
                    message:
                        'Failed to book this item. Please check details and try again.',
                    btnText: SNACKBAR_BUTTON_TYPES.CLOSE,
                    btnFunc: () => errorDispatch({type: 'closeSnackBar'}),
                },
            });
        } finally {
            setIsLoading(false);
        }
    };

    const getBookingInfo = () => {
        return {
            borrowerAddress: borrowerAddress,
            borrowerId: user.id,
            itemId: item.id,
            status: 'APPLIED',
            error: false,
            deliveryOption: getDeliveryOption(
                isDeliverySelected,
                isPickupSelected,
            ),
            startDate: startDate,
            endDate: endDate,
            totalPrice: bookingCalculator?.calculateTotalPrice(),
            itemPrice: item.price,
            discount: item.discount,
            deliveryPrice: isDeliverySelected ? item.deliveryPrice : 0,
            pickupPrice: isPickupSelected ? item.pickupPrice : 0,
            timeOffset: new Date().getTimezoneOffset(),
            rescheduleBookingId: rescheduleBookingId,
        };
    };

    const unblockUser = async () => {
        try {
            let blockedUsersRequest = new CometChat.BlockedUsersRequestBuilder()
                .setLimit(10)
                .build();
            const blockedUsers = await blockedUsersRequest.fetchNext();
            let userId, blockedId;
            // Applicant has blocked the item owner
            if (blockedUsers.find(user => user.getUid() === item.userId)) {
                userId = user.id;
                blockedId = item.userId;
            } else {
                // Item owner has blocked the applicant
                userId = item.userId;
                blockedId = user.id;
            }
            await CometChatInstance.delete(`/users/${userId}/blockedusers`, {
                headers: {apiKey: process.env.REACT_APP_CHAT_API_KEY},
                data: {blockedUids: [blockedId]},
            });
        } catch (e) {
            // console.log(e)
        }
    };

    const sendEnquiry = async (item: Item, type: 'BORROW' | 'EXTENSION') => {
        await unblockUser();
        const textMessage = new CometChat.TextMessage(
            item.userId,
            type === 'BORROW'
                ? `${user.firstName} ${user.lastName} has enquired about your ${item.title}`
                : `${user.firstName} ${user.lastName} has requested an extension about your ${item.title}`,
            CometChat.RECEIVER_TYPE.USER,
        );
        textMessage.setMetadata({
            enquiry: true,
            itemName: item.title,
            bookingDate: new Date(),
        });
        try {
            await CometChat.sendMessage(textMessage);
        } catch (error) {
            // console.log(error)
        }
    };

    const makeBooking = async (bookingInfo: CreateBooking, item: Item) => {
        const {data} = await Instance.post('/bookings', bookingInfo);
        await sendEnquiry(item, 'BORROW');
        if (!data) return;
        history.push({
            pathname: `/item/${item.id}`,
            state: {bookingCreated: true, price: bookingInfo.totalPrice},
        });
    };

    const requestExtension = async () => {
        if (
            !bookingDuration ||
            !extensionPrice ||
            !endDate ||
            !appliedEndDate ||
            !bookingCalculator
        )
            return;
        try {
            setIsLoading(true);
            await BookingService.requestExtension(bookingDuration.bookingId, {
                endDate: endDate.toISOString(),
                startDate: appliedEndDate.toISOString(),
                itemPrice: item.price,
                discount: item.discount,
                // totalPrice: parseInt(bookingCalculator.calculateTotalPrice()),
                totalPrice: extensionPrice ? extensionPrice : 0,
            });
            await sendEnquiry(item, 'EXTENSION');
            history.push({
                pathname: `/user/trades`,
                state: {extensionCreated: true},
            });
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                // console.log(error.response)
            }
            if (error.message.includes('extension overlaps')) {
                errorDispatch({
                    type: 'openSnackBar',
                    data: {
                        message:
                            'Booking extension overlaps with existing bookings.',
                        btnText: SNACKBAR_BUTTON_TYPES.CLOSE,
                        btnFunc: () => errorDispatch({type: 'closeSnackBar'}),
                    },
                });
            } else {
                errorDispatch({
                    type: 'openSnackBar',
                    data: {
                        message:
                            'Failed to apply an extension to this item. Please check details and try again.',
                        btnText: SNACKBAR_BUTTON_TYPES.CLOSE,
                        btnFunc: () => errorDispatch({type: 'closeSnackBar'}),
                    },
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='ApplicationOverviewContainer'>
            <div className='ItemOverviewCardContainer'>
                <span className='ApplicationOverviewHeader'>
                    Application Overview
                </span>
                <ApplicationItemCard item={item} />
            </div>
            <div>
                <span className='ApplicationOverviewSubHeader'>
                    Itemised Costs
                </span>
                {mode === 'APPLY' && bookingCalculator ? (
                    <BorrowApplicationCosts
                        item={item}
                        isDeliverySelected={isDeliverySelected}
                        isPickupSelected={isPickupSelected}
                        bookingCalculator={bookingCalculator}
                        startDate={startDate}
                        endDate={endDate}
                    />
                ) : (
                    bookingCalculator && (
                        <ExtendApplicationCosts
                            extensionPrice={extensionPrice}
                        />
                    )
                )}
                <div className='ItemOverviewItemContainer'>
                    <span className='ApplicationOverviewSubHeader'>Dates</span>
                    <span
                        onClick={() =>
                            dispatch({
                                type: 'setPage',
                                data: 'ItemAvailability',
                            })
                        }
                        className='ItemOverviewEditButton'
                    >
                        Edit Dates
                    </span>
                </div>
                {mode === 'APPLY'
                    ? startDate &&
                      endDate && (
                          <BorrowOverviewFooter
                              startDate={startDate}
                              endDate={endDate}
                          />
                      )
                    : endDate &&
                      appliedEndDate && (
                          <ExtendOverviewFooter
                              endDate={endDate}
                              originalEndDate={appliedEndDate}
                          />
                      )}
            </div>
            <Button
                // onClick={() => setIsModalVisible(true)}
                onClick={() => {
                    !user?.stripe?.customerId
                        ? setAddCardOpen(true)
                        : setIsModalVisible(true);
                }}
                text='Next'
                style={{width: '75%', alignSelf: 'center', marginTop: '1rem'}}
            />
            <AgreementModal
                title={'Borrowers Agreement'}
                content={
                    "Be sure to read over your borrower's rights (Found on our website  <a href='/rental_agreement'>click here to check agreement</a>) and that you have the right licencing and permissions to operate this item. By tapping the Yes button you agree that you understand these terms."
                }
                isLoading={isLoading}
                open={isModalVisible}
                onClose={() => setIsModalVisible(false)}
                onClick={() =>
                    mode === 'APPLY' ? saveBooking() : requestExtension()
                }
            />
            {/* <AddCardModel
        addCardOpen={addCardOpen}
        setAddCardOpen={setAddCardOpen}
        addcardFunction={() => {
          setAddCardOpen(false)
          history.push({
            pathname: `/user/account`,
          })
        }}
      /> */}
        </div>
    );
}
