const monthArray = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const dayArray = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

const fullNameDayArray = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
];

const bookingStatusesArray = [
    'APPLIED',
    'APPROVED',
    'REJECTED',
    'TO_RESCHEDULE',
    'LENDER_CONFIRMED',
    'BORROWER_CONFIRMED',
    'BOTH_CONFIRMED',
    'ITEM_RETURNED',
    'BORROWER_REVIEWED',
    'LENDER_REVIEWED',
    'BOTH_REVIEWED',
];

const categoriesArray = [
    'DIY & Garden',
    'Outdoor & Sport',
    'Photography',
    'Parties & Events',
    'Vehicle',
    'Closet',
    'Kitchen',
    'Gaming & Toys',
    'Musical',
    'Household',
    'Babies & Kids',
    'Electronics',
    'Spaces',
    'Other',
];

export {
    monthArray,
    dayArray,
    fullNameDayArray,
    bookingStatusesArray,
    categoriesArray,
};
