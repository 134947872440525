import React from 'react';
import './ContactTextInput.css';
import ValidationPopup from '../../ValidationPopup/ValidationPopup';

const ContactTextInput = ({
    label,
    inputValue,
    setInput,
    type = 'text',
    errorMessage = '',
}) => {
    return (
        <div className='contact_text_input_container'>
            <p className='contact_text_label'>{label}</p>
            {label !== 'Your Message' ? (
                <input
                    type={type}
                    className='contact_text_input_field'
                    value={inputValue}
                    onChange={e => setInput(e.target.value)}
                />
            ) : (
                <textarea
                    type={type}
                    className='contact_textarea_input_field'
                    value={inputValue}
                    onChange={e => setInput(e.target.value)}
                />
            )}
            {errorMessage ? (
                <ValidationPopup errorText={errorMessage} label={label} />
            ) : null}
        </div>
    );
};

export default ContactTextInput;
