import { useState } from 'react';
import Button from '../../../components/Button/Button';
import './Support.css';
import AccountService from '../../../services/account';
import useErrorState from '../../../util/reducers/errorContext';
import { SNACKBAR_BUTTON_TYPES } from '../../../assets/Data/LBSEnum';
import { FaUpload } from "react-icons/fa";
import { FileService } from '../../../services/FileService';
import { v4 as uuidv4 } from 'uuid';
import { CircularProgress, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import getImage from '../../../util/getImage';
import { supportFolderName } from '../../../util/constant';


const Support = ({ setAccountContent }) => {
    const [reason, setReason] = useState('');
    const [experience, setExperience] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { errorDispatch } = useErrorState();
    const accountService = new AccountService();
    const [reasonError, setReasonError] = useState('');
    const [experienceError, setExperienceError] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [newFiles, setNewFiles] = useState([]);
    const classes = useStyles();
    const maxImages = 4;
    const maxVideo = 1;
    const MAX_VIDEO_SIZE = 200 * 1024 * 1024; // 200MB in bytes


    const handleFileUpload = async ({ target }) => {
        try {
            const files = target.files;
            if (!files.length) return;
            let currentImagesCount = newFiles.filter(file => file.type === 'image').length;
            let currentVideoCount = newFiles.filter(file => file.type === 'video').length;

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                if (file.type.startsWith('image/')) {
                    if (currentImagesCount < maxImages) {
                        currentImagesCount++;
                    } else {
                        alert('Maximum 4 images can be uploaded');
                        return;
                    }
                } else if (file.type.startsWith('video/')) {
                    if (currentVideoCount < maxVideo) {
                        if (file.size > MAX_VIDEO_SIZE) {
                            alert('Video size exceeds the limit of 200MB');
                            return;
                        }
                        currentVideoCount++;
                    } else {
                        alert('Only 1 video can be uploaded');
                        return;
                    }
                }
            };
            setIsUploading(true);

            try {
                const uploadResponse = await FileService.uploadMultipleVideo(files, supportFolderName);
                if (typeof uploadResponse === 'boolean') {
                    alert('Error while uploading the images or video');
                    setIsUploading(false);
                    return;
                }

                const fileLinks = [];

                for (let i = 0; i < files.length; i++) {
                    const isImageType = files[i].type.startsWith('image/');
                    if (isImageType) {
                        fileLinks.push({
                            fileKey: uploadResponse[i],
                            name: files[i].name,
                            size: files[i].size,
                            lastModified: files[i].lastModified,
                            type: 'image',
                            preview: URL.createObjectURL(files[i]),
                            isImageType: true,
                        });
                    }
                    else {
                        fileLinks.push({
                            fileKey: uploadResponse[i],
                            name: files[i].name,
                            size: files[i].size,
                            lastModified: files[i].lastModified,
                            type: 'video',
                            preview: URL.createObjectURL(files[i]),
                            isVideoType: true,
                        });
                    }
                }
                setIsUploading(false);
                setNewFiles([...newFiles, ...fileLinks]);
            }
            catch (error) {
                console.log('error to upload image', error);
                setIsUploading(false);
            }
        } catch (error) {
            console.log('While choosing file error', error);
            setIsUploading(false);

        }
    };

    const removeImage = (deleteIndex) => {
        const updatedFiles = newFiles.filter((_, index) => index !== deleteIndex);
        setNewFiles(updatedFiles);
    }

    const handleSubmit = async () => {
        try {
            if (reason === '') {
                setReasonError('Reason is required');
            }
            if (experience === '') {
                setExperienceError('About is required');
            }
            if (reason !== '' && experience !== '') {
                setIsLoading(true);

                let files = [];


                if (newFiles && newFiles.length > 0) {
                    newFiles.forEach((item) => {
                        if (item?.isImageType) {
                            files.push({
                                fileKey: item?.fileKey,
                                isImageType: true,
                            });
                        }
                        else {
                            files.push({
                                fileKey: item?.fileKey,
                                isVideoType: true,
                            });
                        }
                    });
                }

                const data = await accountService.createSupport({
                    subject: reason,
                    message: experience,
                    files
                });
                if (!data) throw Error;
                errorDispatch({
                    type: 'openSnackBar',
                    data: {
                        message: 'Reason for support submitted successfully!',
                        btnText: SNACKBAR_BUTTON_TYPES.CLOSE,
                        btnFunc: () => errorDispatch({ type: 'closeSnackBar' }),
                    },
                });
                setNewFiles([]);
                setAccountContent('Account');
                setReasonError('');
                setExperienceError('');
            }
        } catch (error) {
            errorDispatch({
                type: 'openSnackBar',
                data: {
                    message:
                        'Failed to submit a support request. Please check your details and try again.',
                    btnText: SNACKBAR_BUTTON_TYPES.CLOSE,
                    btnFunc: () => errorDispatch({ type: 'closeSnackBar' }),
                },
            });
        } finally {
            setIsLoading(false);
        }
    };

    const renderItemImages = images => {
        if (isUploading) {
            return (
                <CircularProgress color='inherit' size={20} />
            )
        }
        return images.map((image, index) => (
            <div className='upload_item_image_box support-list' key={index}>
                <IconButton
                    aria-label='delete'
                    className={classes.buttonDelete}
                    onClick={() => removeImage(index)}
                >
                    <RemoveIcon className={classes.icon} />
                </IconButton>
                {image.type === 'video' ?
                    <video controls autoPlay={false} muted>
                        <source src={image.preview} />
                        Your browser does not support the video tag.
                    </video>
                    :
                    <img
                        src={image.preview}
                        alt=''
                        className='upload_item_image'
                    />
                }
            </div>
        ));
    };

    return (
        <div className='Support_Container'>
            <div className='Support_Header'>What issue are you experiencing?</div>
            <div className='Suppor_Description'>
                Let us know in the box below how our support team can help you to make
                the most of your Little Big Shed journey.
            </div>
            <input
                type='text'
                className='Support_Input_Field'
                placeholder='Your Reason...'
                onChange={e => {
                    setReason(e.target.value);
                    setReasonError('');
                }}
                maxLength='100'
            />
            {reasonError !== '' && (
                <div className='InLineErrorContainer_support'>
                    <div className='ValidationPopup__Header'>{reasonError}</div>
                </div>
            )}

            <div className='Support_Header'>Upload images and videos of the issue</div>
            <div className='Suppor_Description'>
                To support us to troubleshoot your issue more efficiently, please share
                photos and screen recordings of what is happening. Our team will review
                these and get back in touch with you as soon as possible. Thanks for
                contributing to sharing stuff and doing good.
            </div>
            <textarea
                rows='10'
                className='Support_Textarea'
                placeholder='Tell us about your experience...'
                onChange={e => {
                    setExperience(e.target.value);
                    setExperienceError('');
                }}
                maxLength='1000'
            />
            <div className='upload_attachment'
                onClick={() => document.getElementById('selectFile').click()}
            >
                <FaUpload fontSize="1.5em" color="#ac172c" className="upload-icon" />
                <div>
                    Upload images and videos of the issue.
                </div>
                <input
                    type='file'
                    id='selectFile'
                    accept='image/*, video/*'
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                    multiple
                />
            </div>

            <div className='support_attachment_list'>
                {
                    renderItemImages(newFiles)
                }
            </div>
            {experienceError !== '' && (
                <div className='InLineErrorContainer_support'>
                    <div className='ValidationPopup__Header'>
                        {experienceError}
                    </div>
                </div>
            )}
            <Button
                text='Submit'
                onClick={handleSubmit}
                isLoading={isLoading}
                isDisabled={isLoading || isUploading}
            />
        </div>
    );
};

const useStyles = makeStyles({
    button: {
        width: 40,
        height: 40,
        backgroundColor: '#B43B4C',
        '&:hover': {
            backgroundColor: '#cf3247',
        },
    },
    icon: {
        fontSize: 20,
        color: '#FFF',
    },
    buttonDelete: {
        position: 'absolute',
        top: -6,
        right: -10,
        width: 28,
        height: 28,
        zIndex: 3,
        backgroundColor: '#B43B4C',
        '&:hover': {
            backgroundColor: '#cf3247',
        },
    },
});

export default Support;
