const addBankReducer = (state, action) => {
    switch (action.type) {
        case 'setFirstName':
            return {
                ...state,
                firstName: action.data,
            };
        case 'setLastName':
            return {
                ...state,
                lastName: action.data,
            };
        case 'setEmail':
            return {
                ...state,
                email: action.data,
            };
        case 'setPhoneNumber':
            return {
                ...state,
                phoneNumber: action.data,
            };
        case 'setPassword':
            return {
                ...state,
                password: action.data,
            };
        case 'setImage':
            return {
                ...state,
                image: action.data,
            };
        case 'setImageLink':
            return {
                ...state,
                imageLink: action.data,
            };
        case 'setAccountNumber':
            return {
                ...state,
                accountNumber: action.data,
            };
        case 'setBSB':
            return {
                ...state,
                BSB: action.data,
            };
        case 'setPaymentMethod':
            return {
                ...state,
                paymentMethod: action.data,
            };
        case 'setWebsite':
            return {
                ...state,
                website: action.data,
            };
        case 'setIdFrontImage':
            return {
                ...state,
                idFrontImage: action.data,
            };
        case 'setIdBackImage':
            return {
                ...state,
                idBackImage: action.data,
            };
        case 'setIdFrontImageLink':
            return {
                ...state,
                idFrontImageLink: action.data,
            };
        case 'setIdBackImageLink':
            return {
                ...state,
                idBackImageLink: action.data,
            };
        case 'setAddDocFrontImage':
            return {
                ...state,
                addDocFrontImage: action.data,
            };
        case 'setAddDocFrontImageLink':
            return {
                ...state,
                addDocFrontImageLink: action.data,
            };
        case 'setDateOfBirth':
            return {
                ...state,
                dateOfBirth: action.data,
            };

        case 'setAddress':
            return {
                ...state,
                address: action.data,
            };
        case 'SetAccountHolderName':
                return {
                    ...state,
                    accountHolderName: action.data,
                };
        case 'SetBankName':
                return {
                    ...state,
                    bankName: action.data,
                };
        case 'SetSwiftCode':
            return {
                ...state,
                swiftCode: action.data,
            };
        case 'reset':
            return {};
        default:
            return state;
    }
};

export default addBankReducer;
