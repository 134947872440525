import React, {useState} from 'react';
import './DisputeBookingModal.css';
import {Dialog, DialogContent} from '@material-ui/core';
import StatusButton from '../../tradeCalendar/tradeCalendarStatusPanel/StatusButton';
import {BookingAction} from '../../../types/Booking';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import UploadAttachmentPictures from './uploadAttachment';
import Button from '../../../components/Button/Button';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    isItemOwner: boolean;
    handleSubmitDispute: (payload: any) => Promise<void>;
    isLoading: boolean;
};

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
        padding: '6px 10px 6px 0px',
    },
    icon: {
        borderRadius: 3,
        width: 20,
        height: 20,
        boxShadow: 'inset 0 0 0 1px #ac172c, inset 0 -1px 0 #ac172c',
        backgroundColor: '#f5f8fa',
        backgroundImage:
            'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#ac172c',
        backgroundImage:
            'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 20,
            height: 20,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fillRule='evenodd' clipRule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#ac172c',
        },
    },
});

const DisputeBookingModal = ({
    isOpen,
    onClose,
    // handleBookingAction,
    isItemOwner,
    isLoading,
    handleSubmitDispute,
}: Props) => {
    const [disputAttachments, setDisputAttachments] = useState<string[]>([]);
    const [disputAttachmentsLinks, setDisputAttachmentsLinks] = useState<
        string[]
    >([]);
    const [disputReason, setDisputReason] = useState<any[]>([]);
    const [disputeComment, setDisputeComment] = useState('');

    const classes = useStyles();

    const Disputee = isItemOwner ? ' Borrower' : 'Lender';

    const reasonsArr = [
        {
            label: `${Disputee} has failed to arrive`,
            value: false,
        },
        {
            label: `${Disputee} refuses to return item`,
            value: false,
        },
        {
            label: `${Disputee} had an emergency to attend`,
            value: false,
        },
        {
            label: 'Unknown reason',
            value: false,
        },
        {
            label: 'Other',
            value: false,
        },
    ];

    const handleCheck = (event: any) => {
        let filterData = disputReason;
        if (disputReason.some(dt => dt.reason === event.target.name)) {
            filterData = disputReason.filter(
                dt => dt.reason !== event.target.name,
            );
        } else {
            let obj = {reason: event.target.name};
            filterData = [...filterData, obj];
        }
        setDisputReason(filterData);
    };
    const isValidate = () => {
        if (disputeComment && disputReason.length > 0) {
            return false;
        } else {
            return true;
        }
    };

    const handleSubmit = () => {
        let disputePayload: any = {
            comment: disputeComment,
            reason: disputReason,
        };
        if (disputAttachmentsLinks.length > 0) {
            disputePayload.attachments = disputAttachmentsLinks;
        }
        handleSubmitDispute(disputePayload);
    };
    const isCheck = (label: string) => {
        return disputReason.some(dt => dt.reason === label);
    };
    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogContent className='DisputeModalContainer'>
                <div className='DisputeModalTextContainer'>
                    <div className='DisputeBookingModalHeader'>
                        <div className='DisputeBookingModalTitle'>Despute</div>
                        <button className='DisputeCloseBtn'>
                            <CloseIcon onClick={() => onClose()} />
                        </button>
                    </div>
                    <div className='DisputeModalSubHeading'>Despute Reason</div>
                    <div className='DisputeModalText'>{`Why has your ${Disputee} failed ?`}</div>
                    {reasonsArr?.map((dt, index) => {
                        return (
                            <div key={index} className='checkbox-container'>
                                <Checkbox
                                    className={classes.root}
                                    disableRipple
                                    color='default'
                                    name={dt.label}
                                    checkedIcon={
                                        <span
                                            className={clsx(
                                                classes.icon,
                                                classes.checkedIcon,
                                            )}
                                        />
                                    }
                                    onChange={e => handleCheck(e)}
                                    icon={<span className={classes.icon} />}
                                    inputProps={{
                                        'aria-label': 'decorative checkbox',
                                    }}
                                    checked={isCheck(dt.label)}
                                />
                                <div>{dt.label}</div>
                            </div>
                        );
                    })}

                    <div className='DisputeModalSubHeading'>Extra Details</div>
                    <textarea
                        className='DesputeModal-TextArea'
                        value={disputeComment}
                        onChange={e => setDisputeComment(e.target.value)}
                        maxLength={254}
                    />

                    <div className='DisputeModalSubHeading'>Attachments</div>
                    <UploadAttachmentPictures
                        disputAttachments={disputAttachments}
                        setDisputAttachments={setDisputAttachments}
                        disputAttachmentsLinks={disputAttachmentsLinks}
                        setDisputAttachmentsLinks={setDisputAttachmentsLinks}
                    />
                    <div className='DisputeSubmit-button'>
                        <Button
                            isLoading={isLoading}
                            onClick={() => handleSubmit()}
                            isDisabled={isValidate()}
                            text='submit'
                        />
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default DisputeBookingModal;
