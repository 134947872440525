import './NoContent.css';
import NoContentImage from '../../assets/Images/NoContent.png';
import {useHistory} from 'react-router';

export default function NoContent({header, text, onButtonClick, buttonText}) {
    const history = useHistory();

    return (
        <div className='NoContentContainer'>
            <div className='NoContentTextContainer'>
                <span className='NoContentHeaderText'>{header}</span>
                <span className='NoContentText'>{text}</span>
            </div>

            <img src={NoContentImage} className='NoContentImage'></img>

            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '8px',
                    justifyContent: 'center'
                }}
            >
                {buttonText && (
                    <div className='NoContentButton' onClick={onButtonClick}>
                        {buttonText}
                    </div>
                )}
                {buttonText === 'Start Lending Items' && (
                    <div
                        className='NoContentButton'
                        onClick={() => history.push('/search')}
                    >
                        {'Start Borrowing Items'}
                    </div>
                )}
            </div>
        </div>
    );
}
