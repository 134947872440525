import {useContext, useEffect, useState} from 'react';
import useGlobalState from '../../util/useGlobalState';
import Button from '../Button/Button';
import {ReactComponent as Logo} from './../../assets/Logos/LogoRed.svg';
import ValidationTextInput from './ValidationTextInput';
import {validate} from 'validate.js';
import {bankDetailsConstraints} from '../../util/validationConstraints';
export default function BankDetails({context, lenderUpgrade}) {
    const user = useGlobalState().state.user;
    const {state, dispatch} = useContext(context);
    const {accountHolderName, accountNumber, isLenderUpgrade} = state;
    const [errorMessages, setErrorMessages] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (Object.keys(errorMessages).length > 0) {
            const valid = validateInputs();
            if (valid) {
                setErrorMessages({});
                return;
            }
        }
    }, [accountHolderName, accountNumber, errorMessages]);

    const getErrorMessage = inputName => {
        if (Object.keys(errorMessages).length === 0) return null;
        for (const key in errorMessages) {
            if (Object.keys(errorMessages)[0] === inputName)
                return errorMessages[key][0];
        }
    };

    const validateInputs = () => {
        const validationErrors = validate(
            {
                accountHolderName,
                accountNumber,
            },
            bankDetailsConstraints,
        );
        if (validationErrors) {
            setErrorMessages(validationErrors);
            return false;
        }
        setErrorMessages({});
        return true;
    };

    const UpgradeLenderCreateStripe = async () => {
        setIsLoading(true);
        try {
            const valid = validateInputs();
            if (!valid) return;
            setIsLoading(false);
            dispatch({
                type: 'setCurrentPage',
                data: 'Availability',
            });
        } catch (error) {
            // console.log(error.response)
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='RegistrationWrapper'>
            <>
                <div className='LoginMain'>
                    <Logo
                        height='50px'
                        width='50px'
                        style={{marginBottom: '.5em'}}
                    />
                    <div className='LoginHeader'>Payment Details</div>
                    <div className='LoginText'>
                        If you would like to share your shed with users, Little
                        big shed will need to know your payment and banking
                        details to allow you to send and receive money for
                        Little Big Shed trades.
                    </div>
                    <div className='LoginText'>
                        However if you only want to borrow items from other
                        users, we will only need your card details.
                    </div>
                </div>
            </>

            {isLenderUpgrade && (
                <div
                    className='LoginMain'
                    style={!user || lenderUpgrade ? {marginTop: 0} : null}
                >
                    <div className='BankDetailMainTitle'>
                        Bank Deposit Details
                    </div>
                    <div className='BankDetailSubTitle'>
                        Bank details will allow you to upgrade to a lender
                        account.
                    </div>

                    <ValidationTextInput
                        value={accountHolderName}
                        label='Account Holder Name'
                        placeholder='Account Holder Name'
                        onChange={e => {
                            dispatch({
                                type: 'setAccountHolderName',
                                data: e.target.value,
                            });
                        }}
                        errorMessage={getErrorMessage('accountHolderName')}
                    />

                    <ValidationTextInput
                        value={accountNumber}
                        label='Account Number'
                        placeholder='123 456 789'
                        onChange={e => {
                            let value = e.target.value?.replace(
                                /\s|[^0-9]/g,
                                '',
                            );
                            dispatch({
                                type: 'setAccountNumber',
                                data: value ? value : e.target.value,
                            });
                        }}
                        errorMessage={getErrorMessage('accountNumber')}
                        minLength={15}
                        maxLength={16}
                    />

                    <Button
                        style={{marginTop: '20px'}}
                        text='Next'
                        isLoading={isLoading}
                        isDisabled={
                            !accountHolderName ||
                            !accountNumber ||
                            accountNumber.length <= 14
                        }
                        onClick={() => UpgradeLenderCreateStripe()}
                    />
                </div>
            )}
        </div>
    );
}
