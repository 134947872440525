import React from 'react';
import AppleButton from '../../assets/Images/appleStore.png';
// import GooglePlayButton from '../../assets/Images/GooglePlayButton.png'
import GooglePlay from '../../assets/Images/androidStore.png';
import Logo from '../../assets/Logos/LBS_Logo_Flat_Red.jpg';

const FooterDownloadComponent = () => {
    return (
        <div className='footer_container'>
            <div className='footer_header_container'>
                <div className='footer_header_left_section'>
                    <img
                        src={Logo}
                        alt='logo'
                        className='footer_header_logo'
                        style={{marginRight: '1rem'}}
                    />
                    <div className='footer_header_title_box'>
                        <p className='footer_header_main_title'>
                            Little Big Shed in your pocket!
                        </p>
                        <p className='footer_header_sub_title'>
                            Download our mobile app to borrow and lend, whatever
                            you are!
                        </p>
                    </div>
                </div>
                <div className='footer_header_right_section'>
                    <a href={process.env.REACT_APP_IOS_APP_STORE}>
                        <img
                            src={AppleButton}
                            alt='Apple Store'
                            className='app_button'
                        />
                    </a>
                    <a href={process.env.REACT_APP_ANDROID_APK}>
                        <img
                            src={GooglePlay}
                            alt='Google Play'
                            className='app_button google_button'
                        />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default FooterDownloadComponent;
