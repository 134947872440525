import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// if ('serviceWorker' in navigator) {
//   // Register a service worker hosted at the root of the
//   // site using the default scope.
//   console.log(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`)
//   navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`).then(
//     registration => {
//       console.log('Service worker registration succeeded:', registration);
//     },
//     /*catch*/ error => {
//       console.error(`Service worker registration failed: ${error}`);
//     }
//   );
// } else {
//   console.error('Service workers are not supported.');
// }

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);

reportWebVitals();
