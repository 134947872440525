import Footer from '../../../components/marketing/Footer/Footer';
import NavBar from '../../../components/marketing/NavBar/NavBar';

const RentalAgreement = () => {
    return (
        <>
            <NavBar selected='info_hub' />

            <div className='policy-container' style={{width: '100%'}}>
                <div className='policy-header'>
                    <p className='policy-title'>
                        Little Big Shed Rental Agreement
                    </p>
                    <p className='policy-sub-title'>
                        Little Big Shed is an Online Site that connects you with
                        people in your community who are interested in renting
                        your items from you!
                        <br />
                        <br />
                        Do you need some tools for a DIY project or equipment
                        for a camping trip? Don’t want to spend a fortune
                        purchasing these items you may only use once or twice?
                        Or don’t have space to store them? If this sounds like
                        you, then Little big Shed’s online site is perfect for
                        you!
                        <br />
                        <br />
                        By using the Little Big Shed online site (
                        <strong>“Online Site”</strong>), as either a Lender or a
                        Borrower, you agree to be legally bound by this rental
                        agreement, so please make sure that you carefully read
                        the terms and conditions.
                        <br />
                        <br />
                        <strong>1. LENDER AGREEMENT</strong>
                        <br />
                        <br />
                        All Lenders agree:
                        <ol class='policy-list'>
                            <li type='a'>
                                that they are the owners of the Hire Item;
                            </li>
                            <li type='a'>
                                any Hire arrangement is a contract solely
                                between the Borrower and the Lender;
                            </li>
                            <li type='a'>
                                they will accurately and clearly describe the
                                Hire Item and ensure that all photographs of the
                                item truly and accurately depict the Hire Item.
                                Lenders must provide as much information as
                                possible regarding the Hire Item to ensure that
                                the condition of item is clearly and accurately
                                described;
                            </li>
                            <li type='a'>
                                where appropriate, to provide the Borrower with
                                instructions on how to use the Hire Item in the
                                appropriate manner to ensure the safety of the
                                Borrower;
                            </li>
                            <li type='a'>
                                that the Hire Item Listing will be free from any
                                discriminatory, defamatory or maliciously false
                                information and will not contain any offensive
                                or explicit material;
                            </li>
                            <li type='a'>
                                that Little Big Shed may, but has no obligation
                                to, review the use of the Online Site by you and
                                may in its absolute discretion delete, edit,
                                refuse to post or remove in whole or part any
                                information or material uploaded or posted using
                                the Online Site. Little Big Shed may also in its
                                sole and absolute discretion suspend or
                                terminate a Lender’s use of the Online Site in
                                the event Little Big Shed considers a Lender to
                                be in breach of these terms and conditions;
                            </li>
                            <li type='a'>
                                The Lender warrants that the Hire Item is in
                                good working order and fit for the purpose of
                                use;
                            </li>
                            <li type='a'>
                                The Lender warrants that the Hire Item meets the
                                necessary standards, including safety standards,
                                such as a test and tag for electrical items.
                            </li>
                        </ol>
                        <br />
                        <strong>2. BORROWER AGREEMENT</strong>
                        <br />
                        <br />
                        All Borrowers agree:
                        <ol class='policy-list'>
                            <li type='a'>
                                any Hire arrangement is a contract solely
                                between the Borrower and the Lender;
                            </li>
                            <li type='a'>
                                a Hire Item must be returned to the Lender on or
                                before the specified time in the booking as the
                                Hire Return Date (eg. 5pm), unless otherwise
                                agreed in writing between the Borrower and the
                                Lender;
                            </li>
                            <li type='a'>
                                .they are solely responsible for ensuring the
                                use of appropriate personal protective
                                equipment;
                            </li>
                            <li type='a'>
                                they have the necessary training to operate the
                                borrowed item safely and responsibly;
                            </li>
                            <li type='a'>
                                Little Big Shed and Lenders are not liable for
                                any injury or damage caused to or by a Borrower;
                            </li>
                            <li type='a'>
                                Lenders are not responsible for any damage
                                caused to the Hire Item, directly or indirectly,
                                in the Borrower’s home or location in which the
                                Hire Item is being stored and operated,
                                including surrounding items, people, personal
                                property or the Borrower themselves;
                            </li>
                            <li type='a'>
                                To accept all responsibility for any damage
                                caused howsoever to themselves, the Hired Item
                                or any property;
                            </li>
                            <li type='a'>
                                The Borrower must have any required licencing or
                                certification to be able to use/operate the Hire
                                Item(s) and must provide copies of which to the
                                Lender before the Hire.
                            </li>
                        </ol>
                        <br />
                        <strong>3. LITTLE BIG SHED LIABILITY</strong>
                        <br />
                        <br />
                        The Lender and Borrower acknowledge and agree that the
                        Little Big Shed online site is not liable to both the
                        Borrower and the Lender for any costs, including loss of
                        trade or profit incurred or suffered by the Lender or
                        the Borrower as a result of the Hire of the Item(s),
                        whether it be because of delayed or incorrect delivery
                        of any item ordered, or any other reason.
                        <br />
                        <br />
                        <strong>4. LENDER ACCEPTANCE AND UNDERTAKINGS</strong>
                        <br />
                        <br />
                        4.1. Lenders accept and undertake that use of the Little
                        Big Shed Online Site is provided strictly on condition
                        that a Lender does not do any of the following:
                        <ol class='policy-list'>
                            <li type='a'>
                                transmit any information or material which is
                                knowingly incorrect, misleading or deceptive;
                            </li>
                            <li type='a'>
                                transmit any racist, sexist, defamatory,
                                infringing, obscene, abusive, indecent or
                                unlawful information or any material otherwise
                                deemed by Little Big Shed to be threatening,
                                hurtful or otherwise inappropriate;
                            </li>
                            <li type='a'>
                                transmit spam, chain letters, contests, junk
                                mail, surveys or other mass messaging;
                            </li>
                            <li type='a'>
                                use the Online Site for any unlawful purpose;
                            </li>
                            <li type='a'>
                                post or upload files that contain software or
                                other material or information in breach of any
                                person's intellectual property or privacy
                                rights.
                            </li>
                            <li type='a'>
                                upload files that contain viruses, corrupted
                                files, worms, defects or any other similar
                                software or programs that may damage the
                                operation of another's computer or damage any of
                                Little Big Shed’s infrastructure;
                            </li>
                            <li type='a'>
                                delete any author attributions, legal notices or
                                proprietary designations or labels in any file
                                that is uploaded;
                            </li>
                            <li type='a'>
                                advertise or offer to sell any goods or
                                services, or conduct or forward surveys,
                                contests or chain letters other than as approved
                                by Little Big Shed;
                            </li>
                            <li type='a'>
                                download any file posted by another User of the
                                Online Site that you know, or reasonably should
                                know, is unlawful or contrary to these terms and
                                conditions;
                            </li>
                            <li type='a'>
                                excessively use the Online Site in a manner
                                which may hinder or prevent Little Big Shed from
                                providing services to any other persons or which
                                may threaten the integrity or use by any person
                                of the Little Big Shed Online Site; or
                            </li>
                            <li type='a'>
                                authorise, aid, abet encourage or incite any
                                person to do any of the above acts.
                            </li>
                        </ol>
                        <br />
                        4.2. Little Big Shed may choose not to accept any Hire
                        Item submitted to the Online Site.
                        <br />
                        <br />
                        4.3. Little Big Shed may limit the number of Hire Items
                        submitted to the Online Site.
                        <br />
                        <br />
                        4.4 To the extent permitted by law, Little Big Shed
                        reserves the right to keep all records of any and all
                        transactions and communications made through its Online
                        Site between you and other Users (including
                        conversations, User posts, job request bids, comments,
                        feedback, cookies, and I.P. address information) for
                        administration purposes and also holds the right to
                        produce these records in the event of any legal dispute
                        involving Little Big Shed.
                        <br /> <br />
                        <strong>5. ONLINE USE, COPYRIGHT AND LICENCE</strong>
                        <br />
                        <br />
                        5.1 Each Lender and Borrower acknowledge and agree that
                        Little Big Shed cannot and does not represent, warrant
                        or guarantee that:
                        <br />
                        <ol class='policy-list'>
                            <li type='a'>
                                the Online Site will be free from errors or
                                defects;
                            </li>
                            <li type='a'>
                                the Online Site will be accessible at all times;
                            </li>
                            <li type='a'>
                                messages sent through the Online Site will be
                                delivered promptly, or delivered at all;
                            </li>
                            <li type='a'>
                                information received or supplied through the
                                Online Site will be secure, accurate, true or
                                confidential.
                            </li>
                        </ol>
                        <br />
                        5.2 In consideration of Little Big Shed allowing you to
                        access and use the Little Big Shed Online Site you grant
                        to Little Big Shed a non-exclusive, payment free,
                        irrevocable licence to reproduce, modify, delete, adapt
                        and publish any material you post, upload or otherwise
                        transmit via the online site and to sub-licence the same
                        for such purposes as Little Big Shed determines from
                        time to time (subject to the Little Big Shed Privacy
                        Policy) for a period of 12 months.
                        <br />
                        <br />
                        5.3 You warrant that you have all necessary rights in
                        the content that you contribute, the content is not
                        defamatory, that it does not infringe any law and you
                        indemnify agents and contractors against any liability,
                        loss, claim or demand arising out of or in respect of a
                        Little Big Shed , its officers, breach of that warranty.
                        You waive any moral rights you may have in relation to
                        the material.
                        <br />
                        <br />
                        <strong>6. LENDER AND BORROWER OBLIGATIONS</strong>
                        <br />
                        <br />
                        Each Lender and Borrower agrees:
                        <ol class='policy-list'>
                            <li type='a'>
                                they will ensure that their Account does not
                                display any personal contact information at any
                                time such that it can be viewed by any other
                                User.
                            </li>
                            <li type='a'>
                                not give your contact details to any other User
                                and to only correspond with Users through the
                                Online Site;
                            </li>
                            <li type='a'>
                                not to intimidate, harass, impersonate, stalk,
                                threaten, bully or endanger any other User or
                                distribute unsolicited commercial content, junk
                                mail, spam, bulk content or harassment;
                            </li>
                            <li type='a'>
                                not to share your account or login details with
                                any other person and that any use of your
                                account or login by any other person is strictly
                                prohibited. You must immediately notify Little
                                Big Shed of any unauthorised use of your
                                account, login, password or email, or any other
                                breach or potential breach of the security of
                                the Online Site;
                            </li>
                            <li type='a'>
                                not use the Online Site for any purpose other
                                than for making arrangements to lend or borrow
                                Hire Items;
                            </li>
                            <li type='a'>
                                not to act in any way that may harm the
                                reputation of Little Big Shed or associated or
                                interested parties or do anything at all
                                contrary to the interests of Little Big Shed or
                                the Online Site; and
                            </li>
                            <li type='a'>
                                not to make any automated use of the Online Site
                                and you must not copy, reproduce, translate,
                                adapt, vary or modify the Online Site without
                                the express written consent of Little Big Shed.
                            </li>
                        </ol>
                        <br />
                        <strong>7. DAMAGED OR NON-RETURNED ITEMS</strong>
                        <br />
                        <br />
                        7.1 Little Big Shed is not responsible for damaged items
                        or loss of any Hire Items.
                        <br />
                        7.2 If a Hire Item is damaged or unclean beyond what is
                        expected due to reasonable wear and tear, then the
                        Borrower must pay all costs associated with cleaning or
                        repairing the Hire Item.
                        <br />
                        7.3 If the Lender determines, in its absolute
                        discretion, that the Hire Item is damaged beyond repair,
                        or the Hire item is not returned, then the Borrower must
                        pay the replacement fee (shown on all Hire Items at the
                        time of booking) to compensate the Lender for the loss
                        of the item.
                        <br />
                        <br />
                        <strong>8. ACCOUNT OPENING</strong>
                        <br />
                        <br />
                        8.1 As part of the Account opening process, you will be
                        asked to provide personal information and details, such
                        as your email address, first and last name, preferred
                        Username, a secure password, billing, postal address,
                        mobile phone number, and other information as determined
                        by Little Big Shed from time to time (“Personal
                        Information”). We will deal with any Personal
                        Information in accordance with our Privacy Policy.
                        <br />
                        <br />
                        8.2 Once you complete an application for an Account,
                        Little Big Shed may, in its absolute discretion, choose
                        to accept you as a registered User of the Online Site
                        and provide you with an Account.
                        <br />
                        <br />
                        8.3 Little Big Shed may, in its absolute discretion,
                        suspend or cancel your Account for any reason, at any
                        time, including for any failure to comply with these
                        terms and conditions.
                        <br />
                        <br />
                        <strong>9. PAYMENT</strong>
                        <br />
                        <br />
                        9.1 There is no service fee to use Little Big Shed.
                        <br />
                        9.2 Stripe is the verified third-party payment provider.
                        <br />
                        9.3 A 10 CANCELLATIONS AND REFUNDS s a Lender,the only
                        cost of using Little Big Shed is a 2.7% processing fee +
                        30c transaction fee for domestic cards and 3.7%
                        processing fee + 30c transaction fee for international
                        cards, charged by Stripe, our third-party payment
                        provider. This amount is deducted from what you get paid
                        each time your item is rented out.
                        <br />
                        <br />
                        <strong>10. CANCELLATIONS AND REFUNDS</strong>
                        <br />
                        <br />
                        10.1 If a party wants to cancel an order after it has
                        been confirmed by the Lender, the cancelling party must
                        promptly notify the other party through the in-app chat.
                        <br />
                        <br />
                        10.2 If a Borrower cancels an Order after it has been
                        Confirmed by the Lender, they will forfeit any
                        Third-Party Payment fees. If a Borrower cancels more
                        than 24 hours before the Hire Item is due to be
                        delivered by the Lender then there is no fee, other than
                        the Third-Party Payment fees. If a Borrower cancels less
                        than 24 hours before the Hire Item is due to be
                        delivered by the Lender, the Borrower loses 50% of their
                        deposit. The Borrower will also only be refunded 80% of
                        your initial borrow deposit to account for our handling
                        fees.
                        <br />
                        <br />
                        10.3 If a Lender cancels an Order after it has been
                        Confirmed by the Lender, the Online Site will:
                        <ol class='policy-list'>
                            <li type='a'>
                                refund the full Hire Fee to the Borrower
                                (inclusive of all fees);
                            </li>
                            <li type='a'>
                                add a penalty fee to the Lender's account
                                covering the Third-Party Payment Fees incurred
                                by the Borrower and the Commission Amount. All
                                penalty fees will be deducted from the Lender's
                                next payment in the next Lender Payment Run.
                            </li>
                        </ol>
                        <br />
                        10.4 If Little Big Shed determines that a Lender has
                        made an unreasonable amount of cancellations, Little Big
                        Shed may cancel the Lender’s Account and request payment
                        of any outstanding fees, which the Lender agrees to pay
                        within 14 days.
                        <br />
                        <br />
                        10.5 If an item is substantially different as advertised
                        by a Lender (including being damaged or not working),
                        and a Borrower wishes to seek a refund, the Borrower
                        must notify the Lender and Little Big Shed through the
                        Online Site within 12 hours of pickup of the item. The
                        Borrower is required to inspect the item for safety
                        before use. If it is agreed that the item is in a
                        substantially different as advertised, Little Big Shed
                        will:
                        <ol class='policy-list'>
                            <li type='a'>
                                refund the full Hire Fee to the Borrower
                                (inclusive of all fees);
                            </li>
                            <li type='a'>
                                add a penalty fee to the Lender's account
                                covering the Third-Party Payment Fees incurred
                                by the borrower and the Commission Amount. All
                                penalty fees will be deducted from the Lender's
                                next payment in the next Lender Payment Run.
                            </li>
                        </ol>
                        <br />
                        10.6 If the parties cannot agree whether the Hire Item
                        is substantially different to as advertised, the parties
                        must use their best endeavours to resolve the issue.
                        <br />
                        <br />
                        10.7 If Little Big Shed determines that a Lender has
                        received an unreasonable amount of substantially
                        different item returns, Little Big Shed may cancel the
                        Lender’s Account.
                        <br />
                        <br />
                        10.8 Once an Order has begun an Order is non-refundable.
                        <br />
                        <br />
                        <strong>11. THIRD PARTY CONTENT</strong>
                        <br />
                        <br />
                        The Little Big Shed Online Site may contain text,
                        images, data and other content provided by a third party
                        and displayed on the Online Site (“Third-Party
                        Content”). Little Big Shed accepts no responsibility for
                        Third Party Content and makes no representation,
                        warranty or guarantee about the quality, suitability,
                        accuracy, reliability, currency or completeness of any
                        Third-Party Content.
                        <br />
                        <br />
                        <strong>12. DISPUTES BETWEEN USERS</strong>
                        <br />
                        <br />
                        In the event of a dispute, Little Big Shed reserves the
                        right to withhold payment to either party until the
                        dispute is resolved by the parties or by a mediator or
                        arbitrator.
                        <br />
                        <br />
                        <strong>
                            13. LITTLE BIG SHED’S LIABILITY IS LIMITED
                        </strong>
                        <br />
                        <br />
                        13.1 Little Big Shed’s Online Site enables the
                        introduction of Borrowers and Lenders for the purposes
                        of lending and hiring items. Little Big Shed simply
                        collects a commission in consideration for providing
                        this Online Site and does not have any obligation or
                        liability to Borrowers and Lenders in relation to such
                        items or otherwise resulting from the introduction.
                        <br />
                        <br />
                        13.2 Other than as set out in this clause, and to the
                        full extent permitted by law, all rights, remedies,
                        conditions, guarantees and implied and express
                        warranties in respect of any goods or services provided
                        by Little Big Shed are hereby excluded.
                        <br />
                        <br />
                        13.3 Except to the extent that liability cannot be
                        excluded, Little Big Shed, will not be liable to you for
                        claims arising out of or in connection with your access
                        and use of the Little Big Shed’s Online Site and related
                        services whether arising in contract, tort (including
                        negligence), indemnity, strict liability, breach of
                        warranty or statute;
                        <br />
                        <br />
                        13.4 To the full extent permitted by law, Little Big
                        Shed will not be liable to you for loss of use,
                        production, profit, revenue, business, data, contractor
                        anticipated savings or for delay or for any financing
                        costs or increase in operating costs or any economic
                        loss for any indirect or consequential loss or damage.
                        <br />
                        <br />
                        13.5 For the purposes of this section, the term “Little
                        Big Shed” will mean Little Big Shed, KSIT Investments
                        Pty Ltd ACN 638 303 509 its officers, employees,
                        contractors and agents, whether individually or
                        collectively.
                        <br />
                        <br />
                        13.6 Little Big Shed does not accept responsibility for
                        loss or damage to computer systems, mobile phones or
                        other electronic devices arising in connection with your
                        use of the Online Site. All Users must take their own
                        precautions to ensure that the process they employ to
                        access the Online Site does not expose them to the risk
                        of viruses, malicious computer code or other forms of
                        interference
                        <br />
                        <br />
                        13.7 These terms and conditions will survive the
                        expiration or termination of this agreement.
                        <br />
                        <br />
                        <strong>14. TERMINATION</strong>
                        <br />
                        <br />
                        14.1 Little Big Shed reserves the right to terminate a
                        User’s access to any or all of the Online Site
                        (including any Hire Items) at any time without notice,
                        for any reason.
                        <br />
                        <br />
                        14.2 If a User’s use of the Online Site is terminated:
                        <ol class='policy-list'>
                            <li type='a'>
                                the User’s access to the Online Site will be
                                revoked; and
                            </li>
                            <li type='a'>
                                the User will be unable to view the details of
                                all other Users (including contact details,
                                geographic details, any other personal details
                                and Hire Items or requests).
                            </li>
                        </ol>
                        <br />
                        14.3 Should the terminated User have any remaining funds
                        in their Account, they will receive any payment owing in
                        the next payment cycle, less any penalty fees owing to
                        Little Big Shed.
                        <br />
                        <br />
                        14.4 Users may terminate their use of Little Big Shed at
                        any time.
                        <br />
                        <br />
                        <strong>15. LITTLE BIG SHED AND YOUR PRIVACY</strong>
                        <br />
                        <br />
                        By using the Little Big Shed Online Site, you agree to
                        the Little Big Shed Privacy Policy which sets out how
                        Little Big Shed collects, uses and discloses your
                        personal information.
                        <br />
                        <br />
                        <strong>
                            16. WHAT LAW GOVERNS THIS RENTAL AGREEMNT
                        </strong>
                        <br />
                        <br />
                        This Agreement is governed by and construed in
                        accordance with the laws of New Zealand and you
                        irrevocably submit to the exclusive jurisdiction of the
                        courts in this country.
                        <br />
                        <br />
                        <strong>
                            17. LITTLE BIG SHED MAY CHANGE THESE TERMS AND
                            CONDITIONS
                        </strong>
                        <br />
                        <br />
                        17.1 Little Big Shed may at any time and, in its
                        absolute discretion modify or amend the terms and
                        conditions upon which goods or services are supplied by
                        you including such modifications or amendments in these
                        terms of use and publishing them on the Little Big Shed
                        Online Site. Such modifications will be binding on you
                        once displayed on this web site. You should regularly
                        check the terms and conditions displayed on this web
                        site.
                        <br />
                        <br />
                        17.2 Little Big Shed may cease to provide you with
                        access to the Little Big Shed Online Site or any of its
                        services without notice.
                        <br />
                        <br />
                        17.3 All rights not expressly granted are reserved.
                        <br />
                        <br />
                        <strong>18. CONTACT LITTLE BIG SHED</strong>
                        <br />
                        If you have a question, problem or complaint or need to
                        contact us:
                        <br />
                        <br />
                        Postal Address: Suite A, Floor 8 Harbourview Building,
                        152 Quay Street, Auckland Central, Auckland, 1010, New
                        Zealand
                        <br />
                        Email:{' '}
                        <a href='mailto:support@littlebigshed.co.nz'>
                            support@littlebigshed.co.nz
                        </a>
                        <br />
                        Phone: +64 4 280 2840
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default RentalAgreement;
