import {Item} from '../../types/Item';
import './TradeSidebar.css';
import BookingCalculator from '../../util/calculator/BookingCalculator';
import {BookingDuration} from '../../types/Booking';
import {Booking} from '../../types/Booking';

type Props = {
    bookingPriceCalculator: BookingCalculator;
    selectedBookingDuration: BookingDuration;
    isDeliverySelected: boolean;
    isPickupSelected: boolean;
    item: Item;
    selectedBooking: Booking;
};

const TradeSidebarPaymentPanel = ({
    bookingPriceCalculator,
    isDeliverySelected,
    isPickupSelected,
    item,
    selectedBookingDuration,
    selectedBooking,
}: Props) => {
    const {startDate, endDate, itemPrice} = selectedBookingDuration;
    const itemCost = bookingPriceCalculator.calculateCostForItem(
        new Date(startDate),
        new Date(endDate),
        itemPrice,
    );
    // console.log(itemCost,"itemCost");
    return (
        <div className='TradeSidebarSection'>
            <div className='TradeSidebarSubHeading'>
                <span>Itemised Costs</span>
            </div>
            <div className='TradeSidebarCostFlex'>
                <span>Cost for Item </span>
                {itemCost && item && (
                    <span className='ItemOverviewPrice'>${itemCost}</span>
                )}
            </div>
            {/* {isDeliverySelected && item && (
                <div className='TradeSidebarCostFlex'>
                    <span>Item Delivery </span>
                    <span className='ItemOverviewPrice'>{`$${item.deliveryPrice}`}</span>
                </div>
            )}
            {isPickupSelected && item && (
                <div className='TradeSidebarCostFlex'>
                    <span>Item Pickup </span>
                    <span className='ItemOverviewPrice'>{`$${item.pickupPrice}`}</span>
                </div>
            )} */}
            <div
                className='TradeSidebarCostFlex'
                style={{
                    paddingTop: '1rem',
                    borderTop: '1px solid #31364c',
                    paddingBottom: 0,
                }}
            >
                <span className='ItemOverviewSmallText'>Off Peak Discount</span>
                <span className='ItemOverviewSmallText'>
                    -$
                    {bookingPriceCalculator
                        ?.calculateOffPeakDiscount()
                        .toFixed(2)}
                </span>
            </div>
            <div
                className='TradeSidebarCostFlex'
                style={{paddingTop: '0.5rem'}}
            >
                <span>Total Price</span>
                <span className='ItemOverviewPrice'>
                    ${selectedBookingDuration.totalPrice}
                </span>
            </div>
        </div>
    );
};

export default TradeSidebarPaymentPanel;
