import React from 'react';

export default function MorningIcon({color}) {
    return (
        <div style={{height: '60%', width: '100%'}}>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='100%'
                height='100%'
                viewBox='0 0 144 144'
            >
                <defs>
                    <clipPath id='clip-Morning_Slot_icon'>
                        <rect width='144' height='144' />
                    </clipPath>
                </defs>
                <g
                    id='Morning_Slot_icon'
                    data-name='Morning Slot icon'
                    clipPath='url(#clip-Morning_Slot_icon)'
                >
                    <g
                        id='Group_1767'
                        data-name='Group 1767'
                        transform='translate(-0.416 36)'
                    >
                        <g
                            id='Subtraction_33'
                            data-name='Subtraction 33'
                            transform='translate(43.086 28.418)'
                            fill='none'
                        >
                            <path
                                d='M55.728,37.074H1.306a28.517,28.517,0,1,1,54.423,0Z'
                                stroke='none'
                            />
                            <path
                                d='M 53.42789077758789 34.07426071166992 C 53.83055114746094 32.26038360595703 54.03394317626953 30.39986228942871 54.03394317626953 28.51960945129395 C 54.03394317626953 21.70281982421875 51.37966537475586 15.29437065124512 46.56006240844727 10.47476005554199 C 41.73989486694336 5.654590129852295 35.33112335205078 3.000000238418579 28.51433372497559 3.000000238418579 C 14.44568347930908 3.000000238418579 3.000003576278687 14.4480504989624 3.000003576278687 28.51960945129395 C 3.000003576278687 30.39884757995605 3.203378915786743 32.25928115844727 3.606054782867432 34.07426071166992 L 53.42789077758789 34.07426071166992 M 55.72825241088867 37.07426071166992 L 1.305693507194519 37.07426071166992 C 0.4393334984779358 34.31526184082031 3.495483497317764e-06 31.43764114379883 3.495483497317764e-06 28.51960945129395 C 3.495483497317764e-06 12.79419994354248 12.79156303405762 3.55834970378055e-07 28.51433372497559 3.55834970378055e-07 C 36.13409423828125 3.55834970378055e-07 43.29518508911133 2.967240333557129 48.6813850402832 8.353440284729004 C 54.06758499145508 13.73964023590088 57.03394317626953 20.90160942077637 57.03394317626953 28.51960945129395 C 57.03394317626953 31.43852043151855 56.59461212158203 34.31613922119141 55.72825241088867 37.072509765625 L 55.72825241088867 37.07426071166992 Z'
                                stroke='none'
                                fill={color ? color : '#e9d8b4'}
                            />
                        </g>
                        <path
                            id='Path_977'
                            data-name='Path 977'
                            d='M0,0H17.111'
                            transform='translate(71.602 17.111) rotate(-90)'
                            fill='none'
                            stroke={color ? color : '#e9d8b4'}
                            strokeLinecap='round'
                            strokeWidth='3'
                        />
                        <path
                            id='Path_979'
                            data-name='Path 979'
                            d='M7009.3-2826.945h17.113'
                            transform='translate(-6899.101 2882.657)'
                            fill='none'
                            stroke={color ? color : '#e9d8b4'}
                            strokeLinecap='round'
                            strokeWidth='3'
                        />
                        <path
                            id='Path_980'
                            data-name='Path 980'
                            d='M7009.3-2826.945h17.113'
                            transform='translate(-6993.414 2882.657)'
                            fill='none'
                            stroke={color ? color : '#e9d8b4'}
                            strokeLinecap='round'
                            strokeWidth='3'
                        />
                        <path
                            id='Path_981'
                            data-name='Path 981'
                            d='M0,0H17.111'
                            transform='translate(44.31 28.417) rotate(-135)'
                            fill='none'
                            stroke={color ? color : '#e9d8b4'}
                            strokeLinecap='round'
                            strokeWidth='3'
                        />
                        <path
                            id='Path_983'
                            data-name='Path 983'
                            d='M0,0H17.111'
                            transform='translate(98.898 28.418) rotate(-45)'
                            fill='none'
                            stroke={color ? color : '#e9d8b4'}
                            strokeLinecap='round'
                            strokeWidth='3'
                        />
                        <path
                            id='Path_988'
                            data-name='Path 988'
                            d='M7271.822-3031.289h90.032'
                            transform='translate(-7245.236 3103.289)'
                            fill='none'
                            stroke={color ? color : '#e9d8b4'}
                            strokeLinecap='round'
                            strokeWidth='3'
                        />
                        <path
                            id='Path_987'
                            data-name='Path 987'
                            d='M7272.238-3030.725h144.124'
                            transform='translate(-7271.823 3095.006)'
                            fill='none'
                            stroke={color ? color : '#e9d8b4'}
                            strokeLinecap='round'
                            strokeWidth='3'
                        />
                    </g>
                </g>
            </svg>
        </div>
    );
}
