import {BookingDuration} from '../../types/Booking';
import {Item} from '../../types/Item';
import moment from 'moment';

// const calculateTotalPrice = (
//   startDate: string,
//   endDate: string,
//   pricePerSlot: number
// ) => {
//   const start = moment(startDate)
//   const end = moment(endDate)

//   const startTime = start.hours() //moment(itemStartDate).utc().format('HH');
//   const endTime = end.hours() //moment(itemEndDate).utc().format('HH');

//   const msInDay = 24 * 60 * 60 * 1000

//   const startDateNew: Date = new Date(startDate)
//   const endDatNew: Date = new Date(endDate)

//   const dateDifference = Math.round(
//     Math.abs(startDateNew.getTime() - endDatNew.getTime()) / msInDay
//   )

//   const isSameDay = start.isSame(end, 'day')
//   let totalPrice = 0
//   // const startTime = moment(startDate).utc().format('HH')
//   // const endTime = moment(endDate).utc().format('HH')
//   if (isSameDay) {
//     if (
//       (startTime === 8 && endTime === 12) ||
//       (startTime === 13 && endTime === 17)
//     ) {
//       totalPrice += pricePerSlot
//     } else {
//       totalPrice += pricePerSlot * 2
//     }
//   } else {
//     if (dateDifference === 1) {
//       if (startTime === 8) {
//         if (endTime === 12) {
//           totalPrice += dateDifference * pricePerSlot * 2 + pricePerSlot
//         } else if (endTime === 17) {
//           totalPrice += dateDifference * pricePerSlot * 2 + 2 * pricePerSlot
//         }
//       } else if (startTime === 12) {
//         if (endTime === 12) {
//           totalPrice += dateDifference * 2 * pricePerSlot
//         } else if (endTime === 17) {
//           totalPrice += dateDifference * pricePerSlot * 2 + pricePerSlot
//         }
//       } else if (startTime === 13) {
//         if (endTime === 12) {
//           totalPrice += dateDifference * pricePerSlot + pricePerSlot
//         } else if (endTime === 17) {
//           totalPrice += dateDifference * pricePerSlot + 2 * pricePerSlot
//         }
//       } else if (startTime === 17) {
//         if (endTime === 12) {
//           totalPrice += dateDifference * pricePerSlot
//         } else if (endTime === 17) {
//           totalPrice += dateDifference * 2 * pricePerSlot
//         }
//       }
//     } else {
//       if (startTime === 8) {
//         if (endTime === 12) {
//           totalPrice += dateDifference * 2 * pricePerSlot + pricePerSlot
//         } else if (endTime === 17) {
//           totalPrice += dateDifference * pricePerSlot * 2 + 2 * pricePerSlot
//         }
//       } else if (startTime === 12) {
//         if (endTime === 12) {
//           totalPrice += dateDifference * 2 * pricePerSlot
//         } else if (endTime === 17) {
//           totalPrice += dateDifference * pricePerSlot * 2 + pricePerSlot
//         }
//       } else if (startTime === 13) {
//         if (endTime === 12) {
//           totalPrice += dateDifference * 2 * pricePerSlot
//         } else if (endTime === 17) {
//           totalPrice += dateDifference * pricePerSlot * 2 + pricePerSlot
//         }
//       } else if (startTime === 17) {
//         if (endTime === 12) {
//           totalPrice += (dateDifference * pricePerSlot * 2) - pricePerSlot
//         } else if (endTime === 17) {
//           totalPrice += dateDifference * pricePerSlot * 2
//         }
//       }
//     }
//   }
//   return totalPrice.toFixed(0)
// }

export const itemCostsCalCulation = (
    itemStartDate: Date,
    itemEndDate: Date,
    pricePerSlot: number,
    isExtend = false,
) => {
    const start = moment(itemStartDate);
    const end = moment(itemEndDate);
    const startTime = Number(start.hours());
    const endTime = Number(end.hours());
    const msInDay = 24 * 60 * 60 * 1000;
    const startDate: Date = new Date(itemStartDate);
    const endDate: Date = new Date(itemEndDate);

    const dateDifference: number = Math.round(
        Math.abs(endDate.getTime() - startDate.getTime()) / msInDay,
    );
    const isSameDay = start.isSame(end, 'day');
    let totalPrice = 0;

    if (isSameDay) {
        if (
            (startTime === 8 && endTime === 12) ||
            (startTime === 13 && endTime === 17)
        ) {
            totalPrice += pricePerSlot;
        } else if (isExtend && startTime === 12 && endTime === 17) {
            totalPrice += pricePerSlot;
        } else {
            totalPrice += pricePerSlot * 2;
        }
    } else {
        if (dateDifference === 1) {
            if (startTime === 8) {
                if (endTime === 12) {
                    totalPrice +=
                        dateDifference * pricePerSlot * 2 + pricePerSlot;
                } else if (endTime === 17) {
                    totalPrice +=
                        dateDifference * pricePerSlot * 2 + 2 * pricePerSlot;
                }
            } else if (startTime === 12) {
                if (endTime === 12) {
                    totalPrice += dateDifference * 2 * pricePerSlot;
                } else if (endTime === 17) {
                    totalPrice +=
                        dateDifference * pricePerSlot * 2 + pricePerSlot;
                }
            } else if (startTime === 13) {
                if (endTime === 12) {
                    totalPrice += dateDifference * pricePerSlot + pricePerSlot;
                } else if (endTime === 17) {
                    totalPrice +=
                        dateDifference * pricePerSlot + 2 * pricePerSlot;
                }
            } else if (startTime === 17) {
                if (endTime === 12) {
                    totalPrice += dateDifference * pricePerSlot;
                } else if (endTime === 17) {
                    totalPrice += dateDifference * 2 * pricePerSlot;
                }
            }
        } else {
            if (startTime === 8) {
                if (endTime === 12) {
                    totalPrice +=
                        dateDifference * 2 * pricePerSlot + pricePerSlot;
                } else if (endTime === 17) {
                    totalPrice +=
                        dateDifference * pricePerSlot * 2 + 2 * pricePerSlot;
                }
            } else if (startTime === 12) {
                if (endTime === 12) {
                    totalPrice += dateDifference * 2 * pricePerSlot;
                } else if (endTime === 17) {
                    totalPrice +=
                        dateDifference * pricePerSlot * 2 + pricePerSlot;
                }
            } else if (startTime === 13) {
                if (endTime === 12) {
                    totalPrice += dateDifference * 2 * pricePerSlot;
                } else if (endTime === 17) {
                    totalPrice +=
                        dateDifference * pricePerSlot * 2 + pricePerSlot;
                }
            } else if (startTime === 17) {
                if (endTime === 12) {
                    totalPrice += dateDifference * pricePerSlot + pricePerSlot;
                } else if (endTime === 17) {
                    totalPrice += dateDifference * pricePerSlot * 2;
                }
            }
        }
    }
    return totalPrice.toFixed(2);
};

export const calculateOffPeakDiscount = (
    startDate: any,
    endDate: any,
    offPeakDiscount: number,
    pricePerSlot: number,
) => {
    const start = moment(startDate);
    const end = moment(endDate);
    let discount = 0;
    for (start; start.isBefore(end); start.add(1, 'days')) {
        if (start.weekday() >= 1 && start.weekday() <= 5) {
            if (start.isSame(end, 'day')) {
                if (
                    (start.hours() === 8 && end.hours() === 12) ||
                    (start.hours() === 13 && end.hours() === 17)
                ) {
                    discount += pricePerSlot * (offPeakDiscount / 100);
                } else {
                    discount += pricePerSlot * 2 * (offPeakDiscount / 100);
                }
            } else {
                discount += pricePerSlot * 2 * (offPeakDiscount / 100);
            }
        }
    }
    return Number(discount.toFixed(2));
};

export const bookingPrice = (
    appliedStartDate: Date | null,
    endDate: Date | null,
    item: Item,
    bookingDuration: BookingDuration | undefined,
) => {
    let bookingPrice: number = 0;

    const bookingSDate: Date | null = appliedStartDate;
    const bookingEDate: Date | null = endDate;
    const bookingItemPrice: number = item?.price;

    if (
        !bookingSDate ||
        !bookingEDate ||
        !bookingItemPrice ||
        !bookingDuration?.startDate ||
        !bookingDuration?.endDate
    ) {
        return bookingPrice;
    }
    const prevStartDate: Date = new Date(bookingDuration?.startDate);
    let prevEndDate: Date = new Date(bookingDuration?.endDate);
    if (!prevStartDate || !prevEndDate) {
        return bookingPrice;
    }
    const isSameDateTime = moment(prevEndDate).isSame(bookingEDate);
    if (isSameDateTime) {
        prevEndDate = bookingSDate;
    } else {
        let modifiedPrevStartEndDate = moment(prevEndDate);
        if (modifiedPrevStartEndDate.hours() === 12) {
            modifiedPrevStartEndDate = moment(prevEndDate).set({
                hour: 13,
                minute: 0,
                second: 0,
                millisecond: 0,
            });
        } else if (modifiedPrevStartEndDate.hours() === 17) {
            modifiedPrevStartEndDate = moment(prevEndDate)
                .add(1, 'day')
                .startOf('day')
                .add(8, 'hours');
        }
        prevEndDate = modifiedPrevStartEndDate.toDate();

        const extendBookingPrice = Number(
            itemCostsCalCulation(prevEndDate, bookingEDate, bookingItemPrice),
        );
        if (extendBookingPrice > 0) {
            let extendDiscount = 0;
            if (Number(item?.discount) > 0) {
                extendDiscount = Number(
                    calculateOffPeakDiscount(
                        prevEndDate,
                        bookingEDate,
                        item?.discount,
                        bookingItemPrice,
                    ),
                );
            }
            bookingPrice = extendBookingPrice - extendDiscount;
        }
    }
    return bookingPrice;
};
