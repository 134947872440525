import React, {useContext, useState} from 'react';
import {REGISTER_PAGES} from '../../assets/Data/LBSEnum';
import Instance from '../../util/axios';
import Button from '../Button/Button';
import {ReactComponent as Logo} from './../../assets/Logos/LogoRed.svg';

export default function Verification({context, isLender}) {
    const [verificationCode, setVerificationCode] = useState();
    const [error, SetError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const {state, dispatch} = useContext(context);
    const {email} = state;

    const verifyCode = async () => {
        SetError('');
        try {
            setIsLoading(true);
            const status = await sendVerifyCode();
            if (status !== 201) return;
            dispatch({type: 'setCurrentPage', data: REGISTER_PAGES.TNC});
        } catch (error) {
            SetError(
                'The verification code is incorrect. Please try again with a new code.',
            );
        } finally {
            setIsLoading(false);
        }
    };

    const sendVerifyCode = async () => {
        const {status} = await Instance.post('/auth/verifyCodeWithEmail', {
            email: `${email}`,
            code: verificationCode,
        });
        return status;
    };

    return (
        <div className='RegistrationWrapper'>
            <div className='LoginMain'>
                <Logo
                    height='50px'
                    width='50px'
                    style={{marginBottom: '.5em'}}
                />

                <div className='LoginHeader'>Verify Your Identity</div>
                <div className='LoginText'>
                    Log in or create an account to start sharing and borrowing
                    from Little Big Shed.
                </div>

                <div className='LoginHeader'>Verification Code</div>
                <input
                    type='number'
                    placeholder='123456'
                    className='LoginInput'
                    value={verificationCode}
                    onChange={e => {
                        SetError('');
                        const value = e.target.value;
                        if (!isNaN(value) && value.length <= 6) {
                            setVerificationCode(value);
                        }
                    }}
                    style={{marginBottom: '1rem'}}
                />

                {error && <div className='otpError'>{error}</div>}

                <Button
                    isLoading={isLoading}
                    onClick={verifyCode}
                    text='Next'
                />
            </div>
        </div>
    );
}
