const contactUsReducer = (state, action) => {
    switch (action.type) {
        case 'setFirstName':
            return {
                ...state,
                firstName: action.data,
            };
        case 'setLastName':
            return {
                ...state,
                lastName: action.data,
            };
        case 'setEmail':
            return {
                ...state,
                email: action.data,
            };
        case 'setPhoneNumber':
            return {
                ...state,
                phoneNumber: action.data,
            };
        case 'setMessage':
            return {
                ...state,
                message: action.data,
            };
        case 'setIsSubscribe':
            return {
                ...state,
                isSubscribe: action.data,
            };
        default:
            return state;
    }
};

export default contactUsReducer;
