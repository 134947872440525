import React, {useState, useEffect} from 'react';
import './UnSubscribePage.css';
import Instance from '../../util/axios';
import NavBar from '../../components/marketing/NavBar/NavBar';
import Footer from '../../components/marketing/Footer/Footer';
import Button from '../../components/Button/Button';
import useErrorState from '../../util/reducers/errorContext';
import {CircularProgress} from '@material-ui/core';
import {SNACKBAR_BUTTON_TYPES, SCREEN_TYPE} from '../../assets/Data/LBSEnum';
import useGlobalState from '../../util/useGlobalState';
import {ReactComponent as Logo} from './../../assets/Logos/LogoRed.svg';
import EmailRounded from '@material-ui/icons/EmailRounded';

const UnSubscribe = () => {
    const {dispatch, state} = useGlobalState();

    const {isSentEmailCode, isEmailCodeVarified} = state;
    const [isLoading, setIsLoading] = useState(true);
    const [isValidateLoading, setIsValidateLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [verificationCode, setVerificationCode] = useState();
    const [isUnsubscribed, setIsUnsubscribed] = useState(false);

    const {errorDispatch} = useErrorState();

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const emailValue = searchParams.get('email');
        setEmail(emailValue);
        if (!isSentEmailCode) {
            sendVerificationCode(emailValue);
        }
    }, [window.location.search]);

    const verifyCode = async () => {
        try {
            setIsValidateLoading(true);
            const status = await sendVerifyCode();

            if (status !== 201) return;
            dispatch({type: 'setIsEmailCodeVarified', data: true});
        } catch (error) {
            errorDispatch({
                type: 'openSnackBar',
                data: {
                    message:
                        'verify code is incorrect. Please try again with new code.',
                    btnText: SNACKBAR_BUTTON_TYPES.RESEND,
                    btnFunc: async () => {
                        try {
                            errorDispatch({type: 'closeSnackBar'});
                            await Instance.post(
                                '/auth/getVerificationCodeToEmail',
                                {
                                    mobile: `${email}`,
                                },
                            );
                        } catch (error) {
                            // console.log(error)
                        }
                    },
                },
            });
        } finally {
            setIsValidateLoading(false);
        }
    };

    const sendVerifyCode = async () => {
        errorDispatch({type: 'closeSnackBar'});
        const {status} = await Instance.post('/auth/verifyCodeWithEmail', {
            email: `${email}`,
            code: verificationCode,
        });
        return status;
    };
    const unSubscribeHandle = async () => {
        try {
            setIsValidateLoading(true);
            const res = await Instance.post(
                '/contact_us/unSubscribe-newslatter',
                {
                    email: `${email}`,
                },
            );
            if (res.data.status === 201) {
                setIsUnsubscribed(true);
            }
        } catch (error) {
            errorDispatch({
                type: 'openSnackBar',
                data: {
                    message: error.response.data.message,
                    btnText: 'close',
                    btnFunc: async () => {
                        errorDispatch({type: 'closeSnackBar'});
                    },
                },
            });
        } finally {
            setIsValidateLoading(false);
        }
    };

    const sendVerificationCode = async emailValue => {
        try {
            setIsLoading(true);
            const {status, data} = await Instance.post(
                '/auth/getVerificationCodeToEmail',
                {
                    email: `${emailValue}`,
                    screenType: SCREEN_TYPE.UNSUBSRCIBE,
                },
            );
            if (status === 201) {
                dispatch({type: 'setIsSentEmailCode', data: true});
            }
        } catch (error) {
            errorDispatch({
                type: 'openSnackBar',
                data: {
                    message:
                        'Either email is invalid. Please check the details and try again.',
                    btnText: SNACKBAR_BUTTON_TYPES.CLOSE,
                    btnFunc: () => errorDispatch({type: 'closeSnackBar'}),
                },
            });
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <div>
            <>
                <NavBar />
                <div className='unsubscribe-container'>
                    {isLoading ? (
                        <CircularProgress size={30} color={'secondary'} />
                    ) : (
                        <div className='LoginMain'>
                            <Logo
                                height='50px'
                                width='50px'
                                style={{marginBottom: '.5em'}}
                            />
                            {!isEmailCodeVarified ? (
                                <>
                                    <div className='LoginHeader'>
                                        Verify Your Identity
                                    </div>
                                    <div className='LoginText'>
                                        we have sent a verification code to the{' '}
                                        {email} email address associated with
                                        your account. Please check your inbox,
                                        and if you don't see the email within a
                                        few minutes, please check your spam or
                                        junk folder
                                    </div>

                                    <div className='LoginHeader'>
                                        Verification Code
                                    </div>
                                    <input
                                        type='text'
                                        placeholder='123456'
                                        className='unsubscribe-otp-Input'
                                        onChange={e =>
                                            setVerificationCode(e.target.value)
                                        }
                                    />
                                    <div className='resend-OTP-btn'>
                                        {/* <div onClick={() => sendVerificationCode(email)}>
                      Resent Code
                    </div> */}
                                    </div>
                                    <Button
                                        isLoading={isValidateLoading}
                                        onClick={verifyCode}
                                        text='Verify'
                                    />
                                </>
                            ) : (
                                <div>
                                    <div className='LoginHeader'>
                                        Unsbscribe Our Newslatter
                                    </div>
                                    <div className='LoginText'></div>
                                    {!isUnsubscribed ? (
                                        <Button
                                            isLoading={isValidateLoading}
                                            onClick={unSubscribeHandle}
                                            text='Unsubscribe'
                                        />
                                    ) : (
                                        <div>Now you are Unsubscribed</div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <Footer />
            </>
        </div>
    );
};

export default UnSubscribe;
