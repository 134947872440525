import React, {useEffect, useState} from 'react';
import Instance from '../../../../util/axios';
import useGlobalState from '../../../../util/useGlobalState';
import MapsAutocomplete from '../../../../components/mapsAutocomplete/MapsAutocomplete';
import Button from '../../../../components/Button/Button';
import ValidationTextInput from '../../../../components/FormComponents/ValidationTextInput';
import {userAddressConstraints} from '../../../../util/validationConstraints';
import {validate} from 'validate.js';

export default function EditLocation() {
    const [isLoading, setIsLoading] = useState(false);
    const [isSelectAddress, setIsSelectAddress] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});
    const globalState = useGlobalState().state;
    const dispatch = useGlobalState().dispatch;
    const {user} = globalState;

    const [address, setAddress] = useState(user.address);

    useEffect(() => {
        if (Object.keys(errorMessages).length > 0) {
            const valid = validateInputs();
            if (valid) {
                setErrorMessages({});
                return;
            }
        }
    }, [address]);

    const validateInputs = () => {
        const {
            streetNumber,
            streetName,
            suburb,
            state,
            postCode,
            country,
            city,
        } = address;
        const validationErrors = validate(
            {
                streetNumber,
                streetName,
                suburb,
                state,
                postCode,
                country,
                city,
            },
            userAddressConstraints,
        );
        if (validationErrors) {
            setIsSelectAddress(true);
            setErrorMessages(validationErrors);
            return false;
        }
        setErrorMessages({});
        return true;
    };

    const updateLocationDetails = async () => {
        try {
            const valid = validateInputs();
            if (!valid) return;
            const updatedAddress = {
                id: user.address.id,
                ...address,
            };
            setIsLoading(true);
            const {data, status} = await Instance.patch('/users', {
                id: user.id,
                address: updatedAddress,
            });
            if (status !== 200) return;
            dispatch({type: 'setUser', data});
        } catch (error) {
            // console.log(error.response)
        } finally {
            setIsLoading(false);
        }
    };
    const getErrorMessage = inputName => {
        if (Object.keys(errorMessages).length === 0) return null;
        for (const key in errorMessages) {
            if (Object.keys(errorMessages)[0] === inputName)
                return errorMessages[key][0];
        }
    };

    const handleAddressSelect = address => {
        setAddress({
            country: address.country ?? '',
            state: address.state ?? '',
            fullAddress: address.fullAddress ?? '',
            postCode: address.postCode ?? '',
            streetName: address.streetName ?? '',
            streetNumber: address.streetNumber ?? '',
            suburb: address.suburb ? address.suburb : (address.city ?? ''),
            city: address.city ? address.city : (address.suburb ?? ''),
            lat: address.lat ?? 0,
            lng: address.lng ?? 0,
        });
    };

    return (
        <div className='AccountSettings__Container'>
            <div className='AccountSettings__Title'>Location</div>
            <MapsAutocomplete
                setAddress={address => {
                    handleAddressSelect(address);
                }}
                defaultAddress={user.address}
                defaultLocation={user.address.fullAddress}
                defaultLat={user.address.lat}
                defaultLng={user.address.lng}
            />
            <div className='flex flex-col md:mb-5 w-full -translate-y-5'>
                <ValidationTextInput
                    width='100%'
                    value={address.streetNumber}
                    label='Street Number'
                    fontSize='20px'
                    isDisabled={!isSelectAddress}
                    onChange={e =>
                        setAddress({...address, streetNumber: e.target.value})
                    }
                    errorMessage={getErrorMessage('streetNumber')}
                />
                <ValidationTextInput
                    width='100%'
                    value={address.streetName}
                    label='Street Name'
                    isDisabled={!isSelectAddress}
                    fontSize='20px'
                    onChange={e =>
                        setAddress({...address, streetName: e.target.value})
                    }
                    errorMessage={getErrorMessage('streetName')}
                />
                <ValidationTextInput
                    width='100%'
                    label='City'
                    isDisabled={!isSelectAddress}
                    value={address.city}
                    fontSize='20px'
                    onChange={e =>
                        setAddress({...address, city: e.target.value})
                    }
                    errorMessage={getErrorMessage('city')}
                />
                <ValidationTextInput
                    width='100%'
                    value={address.state}
                    label='State'
                    isDisabled={!isSelectAddress}
                    fontSize='20px'
                    onChange={e =>
                        setAddress({...address, state: e.target.value})
                    }
                    errorMessage={getErrorMessage('state')}
                />
                <ValidationTextInput
                    width='100%'
                    label='Post Code'
                    value={address.postCode}
                    isDisabled={!isSelectAddress}
                    fontSize='20px'
                    onChange={e =>
                        setAddress({...address, postCode: e.target.value})
                    }
                    errorMessage={getErrorMessage('postCode')}
                />
                <ValidationTextInput
                    width='100%'
                    label='Country'
                    value={address.country}
                    isDisabled={!isSelectAddress}
                    fontSize='20px'
                    onChange={e =>
                        setAddress({...address, country: e.target.value})
                    }
                    errorMessage={getErrorMessage('country')}
                />
            </div>

            <div className='AccountSettings__ButtonFlex'>
                <Button
                    text='Save Changes'
                    onClick={() => updateLocationDetails()}
                    isLoading={isLoading}
                    style={{width: '60%'}}
                />
            </div>
        </div>
    );
}
